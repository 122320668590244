@import '../../../../styles/Variables';

.dateWithLine {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $geodocs--file-information-history-date--line--color;
  overflow: hidden;
  text-align: center;

  &:before,
  &:after {
    background-color: $geodocs--file-information-history-date--line--background-color;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
}
