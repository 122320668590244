@import '../../../../styles/Variables';

.container {
  font-weight: 400;
  font-size: 14px;
  color: $geodocs--button--disabled--color;
  cursor: not-allowed;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 992px) {
  .container {
    font-size: 12px;
  }
}
