@import '../../../Variables';

.pageWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .heading {
    color: $geodocs--team-management-heading--color;
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    width: 100%;
    display: flex;
    flex-grow: 1;

    .title {
      width: 80%;
    }
    .buttons {
      width: 20%;
      display: flex;
      justify-content: flex-end;

      .editIcon {
        cursor: pointer;
        position: relative;
        top: 3px;
      }
    }
  }

  .row {
    width: 100%;

    &.label {
      font-weight: bold;
      padding: 0 0 16px;
    }
  }

  .halfRow {
    width: 50%;

    &.label {
      font-weight: bold;
      padding: 0 0 16px;
    }
  }

  .flex {
    display: flex;
  }

  .grantingAccess {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .permissionWrapper {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      gap: 16px;
    }

    .dtControlWrapper {
      display: flex;
      width: 100%;

      .accessibleTeamCombobox {
        border-bottom: solid 1px $geodocs--teamManagement-noticed-text-color;
        width: 100%;
      }
    }

    .combobox {
      line-height: 20px;
      font-size: 16px;
    }
  }

  .teamAccessibleTable {
    padding-top: 32px;
  }

  .quickSearch {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 32px;
  }

  .labelBtn {
    visibility: hidden;
    width: 50px;
    font-weight: 700;
    font-size: 14px;
  }

  .grantingAccessBox {
    padding: 24px 0 48px;
    border-bottom: 1px solid $geodocs--team-management-bottom-border--color;

    .headingWrapper {
      display: flex;
      padding: 0 0 24px;
    }

    .searchBoxLabel {
      padding: 0 0 8px;
    }

    .addPermissionContainer {
      align-items: baseline;
      width: 100%;
      background: $geodocs-very-light-gray;
      display: flex;
      padding: 20px;
      box-sizing: border-box;

      &.selectedUserContainer {
        max-height: 86px;

        button {
          align-self: flex-end;
        }
      }

      .searchBox {
        width: 100%;
      }

      .addUserButton {
        margin-left: 16px;
      }
    }
  }
  .save {
    display: flex;
    justify-content: flex-end;
  }
}
