@import '../../../styles/Variables';

.errorMessage {
  color: $geodocs--review-modal--error-message--color;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  text-align: center;
  margin-top: 12px;

  .errorIcon {
    margin-right: 12px;
    font-size: 20px;
    color: $geodocs--review-modal--error-message--color;
  }
}
