@import '../../../styles/Variables';

.container {
  display: table;
  height: 100%;
  width: 100%;
  position: relative;

  .verticalCenter {
    display: table-cell;
    vertical-align: middle;

    .inner {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      text-align: center;

      h1 {
        font-weight: 400;
        font-size: 100px;
        margin: 0;
      }

      .subHeader {
        color: $geodocs--error--sub-header-color;
        font-weight: 400;
        font-size: 36px;
      }
    }
  }
}

.aureconLogo {
  position: absolute;
  left: 100px;
  top: 50px;
  z-index: 5;
}

.aureconBottomLogo {
  position: absolute;
  right: 100px;
  bottom: 50px;
  z-index: 5;
}

.hasHoverState {
  cursor: pointer;
}
