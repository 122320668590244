@import '../../../styles/Variables';

.actionBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $geodocs--divider--color;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  padding: 16px;
  height: 72px;
  box-sizing: border-box;
}

.actionBarSelected {
  background-color: $geodocs--action-bar--selected--background-color;
}
