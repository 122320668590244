@import '../../../../Variables';

.pageHeading {
  border-bottom: 0;
  padding: 0;

  .pageHeadingLabel {
    font-size: 20px;
    font-weight: 400;
    color: $geodocs--system-users-list--page-heading-label-color;
  }
}
