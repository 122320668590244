.textEditor {
  p,
  li,
  a,
  blockquote {
    font-size: 14px;
  }

  p {
    margin: 0 !important;
  }

  :global(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial']::before),
  :global(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before) {
    content: 'Arial';
    font-family: 'Arial', 'Helvetica';
  }

  :global(.ql-font-arial) {
    font-family: 'Arial', sans-serif;
  }

  :global(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='calibri']::before),
  :global(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='calibri']::before) {
    content: 'Calibri';
    font-family: 'Calibri', sans-serif;
  }

  :global(.ql-font-calibri) {
    font-family: 'Calibri', sans-serif;
  }

  :global(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='candara']::before),
  :global(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='candara']::before) {
    content: 'Candara';
    font-family: 'Candara', sans-serif;
  }

  :global(.ql-font-candara) {
    font-family: 'Candara', sans-serif;
  }

  :global(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='century gothic']::before),
  :global(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='century gothic']::before) {
    content: 'Century Gothic';
    font-family: 'Century Gothic', sans-serif;
  }

  :global(.ql-font-century-gothic) {
    font-family: 'Century Gothic', sans-serif;
  }

  :global(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='comic sans ms']::before),
  :global(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='comic sans ms']::before) {
    content: 'Comic Sans';
    font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  }

  :global(.ql-font-comic-sans) {
    font-family: 'Comic Sans MS', 'Comic Sans', cursive;
  }

  :global(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='franklin gothic']::before),
  :global(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='franklin gothic']::before) {
    content: 'Franklin Gothics';
    font-family: 'Franklin Gothic', sans-serif;
  }

  :global(.ql-font-franklin-gothic) {
    font-family: 'Franklin Gothic', sans-serif;
  }

  :global(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='georgia']::before),
  :global(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='georgia']::before) {
    content: 'Georgia';
    font-family: 'Georgia', sans-serif;
  }

  :global(.ql-font-georgia) {
    font-family: 'Georgia', sans-serif;
  }

  :global(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='impact']::before),
  :global(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='impact']::before) {
    content: 'Impact';
    font-family: 'Impact', sans-serif;
  }

  :global(.ql-font-impact) {
    font-family: 'Impact', sans-serif;
  }

  :global(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='tahoma']::before),
  :global(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='tahoma']::before) {
    content: 'Tahoma';
    font-family: 'Tahoma', sans-serif;
  }

  :global(.ql-font-tahoma) {
    font-family: 'Tahoma', sans-serif;
  }

  :global(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='times new roman']::before),
  :global(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times new roman']::before) {
    content: 'Times New Roman';
    font-family: 'Times New Roman', sans-serif;
  }

  :global(.ql-font-times-new-roman) {
    font-family: 'Times New Roman', sans-serif;
  }

  :global(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='verdana']::before),
  :global(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='verdana']::before) {
    content: 'Verdana';
    font-family: 'Verdana', sans-serif;
  }

  :global(.ql-font-verdana) {
    font-family: 'Verdana', sans-serif;
  }

  :global(.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='wingdings']::before),
  :global(.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='wingdings']::before) {
    content: 'Wingdings';
    font-family: 'Wingdings', sans-serif;
  }

  :global(.ql-font-wingdings) {
    font-family: 'Wingdings', sans-serif;
  }

  :global(.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before),
  :global(.ql-snow .ql-picker-options .ql-picker-item[data-value='12px']::before) {
    content: 'Small';
  }

  :global(.ql-snow .ql-picker-options .ql-picker-item[data-value='12px']::before) {
    font-size: 12px;
  }

  :global(.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before),
  :global(.ql-snow .ql-picker-options .ql-picker-item[data-value='24px']::before) {
    content: 'Large';
  }

  :global(.ql-snow .ql-picker-options .ql-picker-item[data-value='24px']::before) {
    font-size: 24px;
  }

  :global(.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='32px']::before),
  :global(.ql-snow .ql-picker-options .ql-picker-item[data-value='32px']::before) {
    content: 'Huge';
  }

  :global(.ql-snow .ql-picker-options .ql-picker-item[data-value='32px']::before) {
    font-size: 32px;
  }

  :global(.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='0']::before),
  :global(.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='0']::before) {
    content: 'Body';
  }

  :global(.ql-snow .ql-editor h1),
  :global(.ql-snow .ql-editor h2) {
    font-family: initial;
  }

  :global(.ql-snow .ql-picker.ql-font) {
    width: 150px;
  }

  :global(.ql-snow .ql-tooltip) {
    margin-left: 95px;
  }

  :global(.ql-clipboard) {
    position: fixed;
    display: none;
  }
}
