.esri-ui {
  z-index: 1;
}

.esri-widget--button {
  margin-top: 5px !important;
}

.esri-basemap-toggle__image {
  width: 70px !important;
  height: 70px !important;
  top: -20px !important;
  left: -20px !important;
}
