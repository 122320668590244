.container {
  padding: 24px 40px;

  h1 {
    text-align: center;
  }
  
  p {
    padding: 0 18px;
  }
}
