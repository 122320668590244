@import '../../../../styles/Variables';

.file {
  font-size: 14px;
  padding: 10px 0;
  display: flex;
  border-bottom: 1px solid $geodocs--file-information-history-date--line--background-color;

  .fileTitle {
    line-height: 30px;
    max-width: 225px;
    overflow-wrap: break-word;
  }

  .actionButton {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .native {
    color: $geodocs--native--file--icon--color;
  }

  .icon {
    border: 1px solid;
    width: 24px;
    height: 24px;

    i {
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -10px 0 0 -10px;
    }
  }
}
