@import '../../../styles/Variables';

.container {
  height: calc(100% - 72px);
  overflow: auto;

  &.loadingTasks {
    height: calc(100% - 130px);
    overflow: auto;
    background: $geodocs--file-table--loading--background-color;
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.taskLoadingSpinner {
  position: fixed;
  top: 50%;
  right: calc(50% - 136px);
  z-index: 100;
}
