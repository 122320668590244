@import '../../../../styles/Variables';

.settingsWidgetContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 32px;
  margin-bottom: -20px;
  div.settingsControl {
    flex: 5 1;
    padding: 0px 16px;
  }
  label.settingsControl {
    flex: 0 1 150px;
    padding: 0px 16px;
  }
}
