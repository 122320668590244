@import '../../../Variables';

.gridGroupWrapper {
  margin-top: 24px;
  size: 20px;

  .breakLine {
    margin-top: 24px;
    border-bottom: solid 1px $geodocs--ProjectManagement-add-project-breakLine-color;
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: $geodocs--system-project-apps-header-color;

    &.settingGroup {
      margin-bottom: 16px;
    }
  }

  .actionButtonsWrapper {
    display: flex;
  }
}

.gridTableWrapper {
  .droppableWrapper {
    width: 100%;

    .appRowWrapper {
      display: flex;
      width: 100%;
      background-color: $geodocs--system-project-apps-table-row-background-color;
      margin: 10px 0;
      align-items: flex-start;

      .row {
        padding: 12px;
        font-size: 14px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box; /* Opera/IE 8+ */
        display: flex;
        align-items: center;
        line-height: 36px;

        .iconWrapper {
          height: 40px;
          .icon {
            width: 40px;
            height: 40px;

            &.cursor {
              cursor: pointer;
            }
          }

          .fileSelection {
            display: none;
          }

          .tooltipError {
            color: $geodocs--system-project-apps-tooltip-error-color;
            span {
              line-height: 20px;
            }
          }
        }
      }

      .textOverflow {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
      }
    }
  }

  .addNewAppButton {
    margin-top: 20px;
    color: $geodocs--system-project-apps-add-new-app-button-color;
  }
}
