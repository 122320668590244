@import '../../../../styles/Variables';
.container {
  display: flex;

  .selectorWrapper {
    flex-grow: 1;
  }

  .label {
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 700;
    display: block;
  }

  .required {
    position: relative;
    color: $geodocs--user-selector--required--color;
    margin-left: 8px;
    vertical-align: sub;
  }

  .buttonWrapper {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
  }

  .collaborateCheckbox {
    margin-bottom: 8px;
  }
}

.accessExpires {
  margin-top: 16px;
  position: relative;
  div:global(.react-date-picker__calendar) {
    z-index: 10;
  }
  div:global(.react-date-picker) {
    font-size: 15px;
  }
}

.requiredField {
  font-size: 12px;
  color: $geodocs--user-selector--required--color;
  margin-top: 8px;
}
