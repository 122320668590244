@import '../../../../styles/Variables';

.modal {
  width: 600px;
  height: 90vh;
  padding: 0;

  .modalActions {
    height: 88px;
    padding: 0 24px;
  }

  .modalBody {
    overflow-y: auto;
    height: calc(90vh - 112px);
    padding: 24px 24px 0 24px;

    .tabContainer {
      display: flex;
      flex-direction: column;

      div:global(.tabs-wrapper .tab-list-active) {
        background-color: $geodocs--button--background-color;
        > span {
          color: $geodocs--white;
        }
      }
      div:global(.tabs-wrapper .tab-list-active::after) {
        background-color: $geodocs--button--background-color;
      }
      div:global(.tabs-wrapper.tabs-medium .tab-list-item) {
        width: 100%;
        border: 0.7px solid $geodocs--button--background-color;
        text-align: center;
        > span {
          width: 100%;
        }
      }

      div:global(.tab-content) {
        box-sizing: border-box;
        padding-top: 16px;
      }

      div:global(.tabs-wrapper.tabs-medium .tab-list-item:nth-child(2)) {
        > span::after {
          content: '*';
          position: relative;
          color: $geodocs--user-selector--required--color;
          margin-left: 4px;
        }
        > span {
          padding-left: 20px;
        }
      }
      div:global(.tabs-wrapper.tabs-medium .tab-list-item::before),
      div:global(.tabs-wrapper.tabs-medium .tab-list-item::after) {
        &:hover {
          z-index: 20;
        }
      }
      div:global(.tabs-wrapper.tabs-medium .tab-list-item:hover:before),
      div:global(.tabs-wrapper.tabs-medium .tab-list-item:hover:after) {
        z-index: 10;
      }
      div:global(.tabs-wrapper.tabs-medium .tab-list-item::before) {
        content: ' ';
        position: absolute;
        top: 0;
        right: -21.9px;
        width: 0;
        height: 0;
        border-top: 18.5px solid transparent;
        border-bottom: 20.7px solid transparent;
        border-left: 20px solid $geodocs--button--background-color;
        z-index: 2;
      }

      div:global(.tabs-wrapper.tabs-medium .tab-list-item) {
        &::after {
          content: ' ';
          position: absolute;
          top: 0;
          right: -20px;
          width: 0;
          height: 0;
          border-top: 18.5px solid transparent;
          border-bottom: 20.7px solid transparent;
          border-left: 20px solid $geodocs--white;
          z-index: 2;
        }

        &:hover {
          &::after {
            z-index: 10;
            border-left: 20px solid mix(white, $geodocs--ascent, 90%);
          }
        }
      }

      div:global(.tabs-wrapper.tabs-medium .tab-list-item:last-child::after) {
        border: none;
      }

      div:global(.tabs-wrapper.tabs-medium .tab-list-item:last-child) {
        &::before {
          border: none;
          border-left: none;
        }

        &:hover {
          &::before {
            z-index: 10;
          }
        }
      }
      div:global(.tabs-wrapper.tabs-medium .tab-list-active::before) {
        border-left: none;
      }

      div:global(.tabs-wrapper.tabs-medium .tab-list-active) {
        &::after {
          border-top: 18.5px solid $geodocs--white;
          border-bottom: 20.7px solid $geodocs--white;
          border-left: 20px solid $geodocs--button--background-color;
        }

        &:hover::after {
          border-top-color: $geodocs--white;
          border-bottom-color: $geodocs--white;
          border-left-color: $geodocs--button--background-color;
        }
      }

      div:global(.tabs-wrapper.tabs-medium .custom-hover) {
        &::after {
          border-top: 18.5px solid mix(white, $geodocs--ascent, 90%);
          border-bottom: 20.7px solid mix(white, $geodocs--ascent, 90%);
        }
      }

      .reviewModalTab {
        display: flex;
        flex-direction: column;
        height: auto;
      }
    }
  }
}
