@import '../../../../styles/Variables';

.resultsModal {
  h1 {
    margin: 24px 0;
  }

  .message {
    display: flex;
    align-items: center;
    margin: 0 0 24px;

    i {
      margin-right: 24px;
      &.success {
        color: $geodocs--upload-file-ready-status--color;
      }

      &.warning {
        color: $geodocs--icon--warning;
      }
    }

    p {
      margin: 0;
    }
  }

  .exportButton {
    padding: 12px;
    border: 1px solid $geodocs--black;
    color: $geodocs--black;
    text-decoration: none;

    &:hover {
      background: $geodocs--upload-form--export-btn--hover-background-color;
      border: 1px solid $geodocs--upload-form--export-btn--hover-background-color;
    }
  }
}
