@import '../../../styles/Variables';

.modal {
  max-width: 1024px;
  height: 90vh;
  padding: 0;
}

.modalActions {
  height: 88px;
  padding: 0 24px;
}

.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(90vh - 112px);
  padding: 24px 24px 0 24px;

  div:global(.dropdown-options) {
    max-height: 128px !important;
    div:global(.dropdown-item .dropdown-item-icon) {
      padding-right: 12px !important;
      margin-right: 0 !important;
    }
    div:global(.dropdown-item > span) {
      padding: 0 12px 0 0px !important;
    }
  }
}

.dropdown-wrapper .dropdown-content .dropdown-options .dropdown-item {
  padding-left: 16px !important;
}

.header {
  padding-top: 24px;
  span {
    height: 100%;
    font-size: 24px;
    line-height: 36px;
    margin-right: 20px;
    vertical-align: middle;

    &.require {
      color: #f37021;
    }
  }
}

.headline {
  align-items: center;
  display: flex;
  font-size: 16px;
  line-height: 24px;
}

.topFilter {
  min-height: 232px;
}

.rowItem {
  padding: 16px 0;
}

.searchBox {
  width: 100% !important;
}

.leftPanel {
  width: 100%;
}

.disablePanel {
  opacity: 0.5;
  cursor: not-allowed;
}

.taskTeamAccordion {
  div:global(.accordion-panel),
  div:global(.accordion-content) {
    margin-bottom: 16px;
  }
}

.selectorWrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.taskTeamHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  span.title {
    font-size: 20px;
    color: $geodocs--project-team-user-selector--panel-title-color;
  }
}

.thematicBreak {
  width: 100%;
  border-top: 1px solid $geodocs--project-team-user-selector--hr-color;
  margin-bottom: 16px;

  &.second {
    margin-left: 8px;
    margin-right: 8px;
  }
}

.selectedUsersWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .selectedUsersLabel {
    display: flex;
    color: $geodocs--project-team-user-selector--selected-users-label-color;
    width: 125px;
    height: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .selectedUsers {
    display: flex;
    align-items: center;
    width: 100%;

    .selectedUserPill {
      margin: 4px;

      .selectedUserPillContent {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          padding: 0 3px;
        }
      }
    }
  }
}

.taskTeamGroupFilterTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  height: 48px;
  .label {
    margin-right: 16px;
  }
}

.taskTeamGroupFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 18px;
  height: 26px;

  .taskTeamGroupFilterLabel {
    margin-left: 16px;
  }
}

.listIcon {
  font-size: 18px;
}

.groupSelect {
  display: flex;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  color: $geodocs--black;

  .listIcon {
    margin-right: 16px;
  }

  .disabled {
    cursor: not-allowed;
  }

  &.taskTeamFilterGroup {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .label {
      font-weight: 700;
      margin-right: 16px;
    }
  }

  &.active {
    background: $geodocs--project-team-user-selector--task-team-wrapper--task-team-item--hover--background-color;
    color: $geodocs--project-team-user-selector--task-team-wrapper--task-team-item--active--color;

    .itemTooltip {
      color: $geodocs--project-team-user-selector--task-team-wrapper--task-team-item--active--color !important;
    }
  }
}

.tooltipWrapper {
  display: flex;
  width: 100%;

  .initiatorUserTooltip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-wrap: break-word;
    word-break: break-all;

    .override {
      background-color: $geodocs--button--background-color;
      margin-right: 12px;
      width: 45px;
      height: 45px;
      font-size: 20px;
      line-height: 23px;
    }

    .userInfo {
      margin-left: 0.5px;
    }

    .userEmail {
      font-size: 12px;
    }
  }

  > span:first-child {
    display: flex;
    width: 100%;
  }
}

.itemTooltip {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.teamChartUserItem {
  display: flex;
  width: 100%;
  .iconInfoWrapper {
    margin-left: 16px;
  }
}

.taskTeamWrapper {
  list-style: none;
  padding: 0;
  margin: 0;

  .groupTileItem {
    border-bottom: solid 1px rgba(0, 0, 0, 0.25);
    height: 32px;
    font-size: 12px;
    line-height: 18px;
    color: $geodocs--project-team-user-selector--task-team-wrapper--group-tile-item--color;
    align-items: center;
    display: flex;
  }

  .taskTeamItem {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 18px;

    &.disabled {
      color: $geodocs--locked-content-color;
      cursor: not-allowed;

      .userName {
        color: $geodocs--locked-content-color;
      }

      &:hover {
        background-color: $geodocs--dropdown-list--background-color;
      }
    }

    &:hover {
      background-color: $geodocs--project-team-user-selector--task-team-wrapper--task-team-item--hover--background-color;
    }

    &.active {
      background: $geodocs--project-team-user-selector--task-team-wrapper--task-team-item--hover--background-color;
      color: $geodocs--project-team-user-selector--task-team-wrapper--task-team-item--active--color;

      .itemTooltip,
      .deliveryTeamRoleTitle {
        color: $geodocs--project-team-user-selector--task-team-wrapper--task-team-item--active--color !important;
      }
    }

    .userName {
      text-transform: capitalize;
      color: $geodocs--black;
      font-size: 12px;
      line-height: 18px;
    }

    .groupTitle {
      color: $geodocs--project-team-user-selector--task-team-wrapper--external-team-role-title--color;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .groupTitleText {
        margin-left: 8.5px;
      }
    }

    .deliveryTeamRoleGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .deliveryTeamRoleTitle {
        display: flex;
        align-items: center;

        &.taskTeam {
          color: $geodocs--project-team-user-selector--task-team-wrapper--task-team-item--active--color;
        }

        &.deliveryTeam {
          color: $geodocs--project-team-user-selector--task-team-wrapper--delivery-team-role-title--color;
        }

        &.externalTeam {
          color: $geodocs--project-team-user-selector--task-team-wrapper--external-team-role-title--color;
        }

        .deliveryTeamRolePersonIcon {
          margin-left: 16px;
          align-items: center;
          display: flex;
          margin-right: 8.5px;
          font-size: 18px;
        }
      }

      .iconInfo {
        margin-left: 16px;
        align-items: center;
        display: flex;
        font-size: 18px;
        font-size: 8px;
      }
    }
  }
}
