@import '../../../styles/Variables';

.modal {
  padding: 24px;
  min-width: 500px;
  max-width: 50%;
  background-color: $geodocs--modal--background-color;
  box-shadow: 0 2px 8px $geodocs--modal--box-shadow-color;
  overflow: hidden;
  position: relative;
  z-index: $geodocs--modal--z-index;
  
}
