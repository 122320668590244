@import './Variables';
@import '@fontsource/firago/index.css';
@import '@fontsource/firago/300-italic.css';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
@import '@arcgis/core/assets/esri/themes/light/main.css';
@import '@arcgis/core/assets/esri/css/main.css';

body,
html {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Arial', -apple-system, BlinkMacSystemFont, 'Georgia', 'Impact', 'Times New Roman', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $geodocs--text--color;
  font-size: $geodocs--text--size;

  p {
    font-size: $geodocs--text--size;
  }
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  position: relative;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: $geodocs--button--color;
  background-color: $geodocs--button--background-color;
  border: 1px solid $geodocs--button--border-color;

  & + button {
    margin-left: 16px;
  }

  &:not(:enabled) {
    cursor: default;
    color: $geodocs--button--disabled--color;
    background-color: $geodocs--button--disabled--background-color;
    border-color: $geodocs--button--disabled--border-color;
  }

  &:hover:enabled {
    color: $geodocs--button--hover--color;
    background-color: $geodocs--button--hover--background-color;
    border-color: $geodocs--button--hover--border-color;
  }

  &:focus:enabled {
    color: $geodocs--button--focus--color;
    background-color: $geodocs--button--focus--background-color;
    border-color: $geodocs--button--focus--border-color;
  }
}

hr {
  border: 1px solid $geodocs--divider--color;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none;
}

.app-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-content {
  &.is-left {
    direction: rtl;

    .dropdown-options {
      direction: ltr;
    }
  }
}
