.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;

  * + * {
    margin-left: 10px;
  }
}

@media screen and (max-width: 992px) {
  .container {
    font-size: 12px;
  }
}
