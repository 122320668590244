@import '../../../../styles/Variables';

.fileWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  padding: 0 16px 0 16px;
  background-color: $geodocs--file-name-wrapper--background-color;

  .fileNameWrapper {
    font-size: 14px;
  }

  .fileStatusWrapper {
    padding-left: 100px;
    display: flex;
    align-items: center;

    .fileIconWrapper {
      i {
        position: relative;
        top: 3px;
        font-size: 16px;
      }

      span {
        padding: 0 20px 0 10px;
        font-size: 14px;
      }
    }
  }
}

.filePage {
  margin-right: 24px;
}
