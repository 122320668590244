@import '../../../styles/Variables';

.layout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 65px 1fr;
  grid-template-rows: 60px 1fr 40px;
  grid-template-areas:
    'navbar navbar'
    'sidebar pageContent'
    'footer footer';
  column-gap: 3px;
  overflow: hidden;
}

.navbar {
  grid-area: navbar;
}

.sidebar {
  grid-area: sidebar;
}

.sidebarOpen {
  grid-template-columns: 200px 1fr;
}

.pageContent {
  grid-area: pageContent;
  overflow: auto;
}

.footer {
  grid-area: footer;
}
