@import '../../../Variables';

.pageWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  position: relative;
  overflow: auto;

  .headingLabel {
    padding: 24px 40px;
    font-size: 20px;
    font-weight: 400;
    color: $geodocs--temporary-access-log--page-heading-label-color;

    &.header {
      margin: 0;
    }
  }

  .projectFilterSection,
  .tableSection {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .projectFilterSection {
    margin-bottom: 2rem;
    padding-left: 40px;
    box-sizing: border-box;

    .programmeOptionWrapper {
      align-items: center;
      display: flex;
      gap: 0.5rem;
      font-weight: 400;
      font-size: 14px;
      color: $geodocs--button--disabled--color;
      cursor: not-allowed;
      width: 100%;
      height: 100%;
    }

    .projectOptionWrapper {
      margin-left: 12px;
      align-items: center;
      display: flex;
      gap: 0.5rem;
    }

    .icon {
      font-size: 14px;
    }

    .selectProjectDropdownWrapper {
      width: 60%;

      .optionItem {
        align-items: center;
        display: flex;
        gap: 0.5rem;
      }

      div:global(.dropdown-options) {
        max-height: 50vh;
      }
    }
  }

  .tableSection {
    height: 100%;
    &.showPaging {
      height: calc(100% - 94px);
    }

    overflow: auto;
    box-sizing: border-box;
    padding-left: 40px;

    .logsTable {
      height: calc(100% - 92px);
      overflow: auto;
      thead {
        :global {
          tr.table-row.is-header {
            > td.table-cell {
              white-space: nowrap;
            }
          }
        }

        .tableListFilter {
          min-width: unset;
          :global {
            .combobox-content {
              .field-wrapper > label {
                .input-field {
                  min-width: unset !important;
                }
              }
            }
          }
        }
      }

      table {
        width: 100%;
        & thead {
          position: sticky;
          top: 0;
          z-index: 1;
        }
        last-child {
          position: sticky;
          right: 0;
          background-color: $geodocs--temporary-access-log--action-column--background-color !important;

          &:hover {
            z-index: $geodocs--temporary-access-log--action-column--hover--z-index;
          }
        }
      }

      .actionCell {
        text-align: center;
      }
    }
  }

  .modalOverlay {
    background-color: $geodocs--content--tab-container-progress--overlay--background-color;
    opacity: 0.9;
  }

  .tableHeadColumn {
    width: fit-content;
  }

  .paginationWrapper {
    position: absolute;
    bottom: 0;
    height: 92px;
    width: 100%;
  }

  .paginationContainer {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 24px;
    box-sizing: border-box;

    .paginationFlexGrow {
      position: absolute;
      right: 48px;
      padding-top: 0.9375rem;
    }
  }

  .savingSpinner {
    padding-left: 16px;
    position: absolute;
    top: 50%;
    right: 50%;
    z-index: 1001;
  }

  .actionButton {
    border-radius: 0;
    padding: 0;
    width: 40px;
    height: 40px;
  }

  .notExpiry {
    background-color: $geodocs--temporary-access-log-ready-status--color;
  }

  .expired {
    background-color: $geodocs--temporary-access-log-expired-status--color;
  }
}
