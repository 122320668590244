@import '../../../styles/Variables';

.modalWrapper {
  z-index: 9999;
  font-size: 14px;

  :global(.modal) {
    position: absolute;
    bottom: 100px;
    width: 100%;
  }

  .modalDivider {
    position: relative;
    border-bottom: 1px solid $geodocs--system-term-condition-modal-divider-color;
    margin: 16px -24px 24px;
  }
}
