@import '../../../../styles/Variables';

.container {
  margin: 0 auto;
  width: 100%;
  max-height: 80%;
  max-width: 80%;

  .goBackBtn {
    margin: 35px 0 0 0px;
  }
}
.contentFiles {
  padding: 12px 24px 24px 24px;
  margin: 16px 0 0;
  background-color: $geodocs--transmittals-item--item-detail-wrapper--background-color;
  display: flex;
  flex-direction: column;
  max-height: calc(80vh - 250px);
  overflow-y: auto;

  .contentFilesHeading {
    font-weight: bold;
    margin: 0 0 16px;
    display: flex;
    align-items: center;

    .headingText {
      margin: 0 0 0 16px;
    }
  }
}
.tableContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0px;
  grid-template-areas:
    'actionBar actionBar'
    'content fileInformation';
  overflow-y: auto;
}
.fileCell {
  display: flex;
  align-items: center;
  .fileIcon {
    transform: scaleY(-1);
    padding: 0 8px 0 0;
  }
}
.showFileInformation {
  grid-template-columns: 1fr 368px;
  max-height: 676px;
}
.label {
  display: flex;
  column-gap: 12px;
  font-weight: 400;
  color: $geodocs--selected-files-label--color;
  margin-left: 16px;
  font-size: 14px;
}
.linkLabel {
  cursor: pointer;
  font-size: 14px;
  color: $geodocs--selected-files-link-label--color;
}
.totalSelected {
  margin-left: 16px;
}
.labelBottom {
  margin: 16px 0 0 0;
  color: $geodocs--go-back-button--text--color;
}
.fileDetails {
  position: relative;
  border-left: 4px solid $geodocs--task-information-details--panel--border-color;
}
.contentFileTable {
  thead {
    position: sticky;
    top: 0;
    z-index: 10;
    :global {
      tr.table-row.is-header {
        > td.table-cell {
          white-space: nowrap;
        }
      }
    }
  }

  table {
    width: 100%;
    & thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
    td:first-child,
    td:nth-child(1) {
      left: 0;
    }
    td:last-child {
      position: sticky;
      right: 0;

      &:hover {
        z-index: $geodocs--file-table--action-column--hover--z-index;
      }
    }
    .checkbox {
      padding: 0 !important;
      width: 24px;
      > div {
        padding: 12px;
        padding-top: 50%;
        padding-bottom: 50%;
      }
    }
  }
}
.native {
  margin-left: 5px;
  color: $geodocs--native--file--icon--color;
}
