@import '../../../Variables';

.fromWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;

  .gridGroup {
    margin-top: 24px;

    .transmittalSetting {
      margin-bottom: 48px;
      font-size: 20px;
    }
  }

  .settingNoticed {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .breakLine {
    margin-top: 24px;
    border-bottom: solid 1px $geodocs--ProjectManagement-add-project-breakLine-color;
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: $geodocs--ProjectManagement-add-project-title-color;

    &.settingGroup {
      margin-bottom: 16px;
    }

    .addProjectActions,
    .updateProjectActions {
      display: flex;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 8px;

    .tooltipText {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      max-width: 200px;
    }

    .actionButton {
      width: 24px;
      height: 24px;

      &.nonePointerEvents {
        pointer-events: none;
      }

      &:active:enabled,
      &:focus:enabled {
        color: $geodocs--black;
        background-color: $geodocs--ascent;
      }
    }
  }

  .checkbox {
    :global {
      div.checkbox {
        display: flex;
        align-items: center;
      }
    }
  }

  .formDisplayField {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;

    .value {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .formControlLabel {
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 14px;

    .required {
      position: inherit;
      color: $geodocs--user-selector--required--color;
      margin-left: 8px;
      margin-top: 4px;
    }
  }

  .projectStatusDot {
    width: 14px;
    height: 14px;
  }
  .projectStatusText {
    font-size: 14px;
    line-height: 16px;
  }

  .inProgress {
    background-color: $geodocs--task--assigned;
  }
  .failed {
    background-color: $geodocs--error;
  }
  .active {
    background-color: $geodocs--task--approved;
  }
  .inactive {
    background-color: $geodocs--transmittals-item--closed--background-color;
  }

  .projectStatusWrapper {
    .statusFailedMessage,
    .statusFailedMessage > a {
      color: $geodocs--error;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .searchBox {
    width: 100%;
  }

  .formItemWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;

    .combobox {
      :global {
        span.combobox-required {
          position: static;
        }
      }
    }
  }
}
