@import '../../../styles/Variables';

.history {
  margin: 0;
  padding: 0;
}

.historyItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 0;
}
