@import '../../../../styles/Variables';

.container {
  margin-top: 15px;

  .selectorWrapper {
    margin-bottom: 12px;
    .labelWrapper {
      margin-bottom: 12px;
    }
  }

  .label {
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 700;
    display: block;
  }

  .required {
    position: relative;
    color: $geodocs--user-selector--required--color;
    margin-left: 8px;
    vertical-align: sub;
  }
}
