@import '../../../../styles/Variables';

.status {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 11px;
  float: right;
  width: max-content;
  margin: 5px;

  &.ready {
    color: $geodocs--upload-file-ready-status--color;
    border: 1px solid $geodocs--upload-file-ready-status--color;
    background-color: $geodocs--upload-file-ready-status--background;
  }

  &.duplicate {
    color: $geodocs--upload-file-duplicate-status--color;
    border: 1px solid $geodocs--upload-file-duplicate-status--color;
    background-color: $geodocs--upload-file-duplicate-status--background;
  }

  &.missingMetadata {
    color: $geodocs--upload-file-duplicate-status--color;
    border: 1px solid $geodocs--upload-file-duplicate-status--color;
    background-color: $geodocs--upload-file-duplicate-status--background;
  }

  &.supersede {
    color: $geodocs--upload-file-supersede-status--color;
    border: 1px solid $geodocs--upload-file-supersede-status--color;
    background-color: $geodocs--upload-file-supersede-status--background;
  }

  &.missingFileExtension {
    color: $geodocs--upload-file-duplicate-status--color;
    border: 1px solid $geodocs--upload-file-duplicate-status--color;
    background-color: $geodocs--upload-file-duplicate-status--background;
  }
}
