.fileSelectorDropdown {
  div:global(.dropdown-options) {
    max-height: 90vh;
  }
}

.dropdownSelected {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
}

.icon {
  font-size: 14px;
  margin-right: 10px;
}

.selectedProgramme {
  font-size: 14px;
  margin-right: 10px;
}

.selectedProject {
  font-size: 14px;
  font-weight: 700;
  margin-right: 24px;
  margin-left: 10px;
}

.selectedTaskTeam {
  > span {
    margin: 0;
  }
}

@media screen and (max-width: 992px) {
  .selectedProgramme {
    font-size: 12px;
  }

  .selectedProject {
    font-size: 12px;
  }
}

.searchSuggestions {
  ul:global(.suggestions) {
    max-height: 90vh;
  }
}
