@import '../../../../styles/Variables';

.layerTree {
  max-width: 90%;
  text-align: center;
  font-size: 14px;
  position: absolute;
  left: -390px;
  transition: transform 0.4s ease-in-out;
  transform: translateX(0%);
  overflow: hidden;
  max-height: calc(100% - 50px);
  display: flex;
  flex-direction: column;

  .headerLabel {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    margin: 0;
    padding: 1rem 0;
    margin-left: 24px;
  }

  .topButton {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  > .appContainer {
    visibility: hidden;
    max-width: 448px;
    width: 448px;
    outline: none;
    overflow-y: auto;
    max-height: calc(100% - 50px);

    > div {
      overflow: auto;
    }

    .treeNode {
      background-color: $geodocs--layer-tree-widget--background-color;

      > div {
        border-bottom: 1px solid $geodocs--divider--color;
        padding: 18px 9px 18px 4px;
        margin: 0 15px 0 20px;
      }
    }
  }

  .locationIcon {
    cursor: pointer;
    margin-right: -3px;
  }
}

.layerTreeActive {
  transform: translateX(92%);
  background-color: $geodocs--layer-tree-widget--background-color;
  box-shadow: 0.125rem 0.125rem 0.25rem $geodocs--layer-tree-widget--box-shadow-color;

  > .appContainer {
    visibility: inherit;
  }
}
