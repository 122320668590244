@import '../../../styles/Variables';

.awaitingReview {
  background-color: $geodocs--task-user-status-indicator--awaiting-review--background-color;
}

.approved {
  background-color: $geodocs--task-user-status-indicator--approved--background-color;
}

.rejected {
  background-color: $geodocs--task-user-status-indicator--rejected--background-color;
}
