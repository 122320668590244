@import '../../../../styles/Variables';

.container {
  font-weight: 400;
  font-size: 14px;
  height: 100%;
  margin-left: 12px;
}

.icon {
  font-size: 14px;
}

.disableProject {
  color: $geodocs--button--disabled--color;
  cursor: not-allowed;
}

div:global(.dropdown-options) {
  div:global(.dropdown-item) {
    span {
      &.container {
        padding-left: 12px !important;
      }
    }
  }
}

.transmittalsSelectorDropdown {
  div:global(.dropdown-options) {
    max-height: 90vh;
  }
}

@media screen and (max-width: 992px) {
  .container {
    font-size: 12px;
  }
  .icon {
    font-size: 12px;
  }
}

.searchSuggestions {
  ul:global(.suggestions) {
    max-height: 90vh;
  }
}
