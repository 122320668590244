@import '../../../../styles/Variables';

.container {
  font-weight: 400;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.icon {
  font-size: 14px;
}

.taskTeamTitle {
  font-weight: 700;
}

.selectedProgramme {
  font-size: 14px;
}

.selectedProject {
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
}
.disableTaskTeam {
  color: $geodocs--button--disabled--color;
  cursor: not-allowed;
}

div:global(.dropdown-options) {
  div:global(.dropdown-item) {
    span {
      &.container {
        padding-left: 24px !important;
      }
    }
  }
}

div:global(.dropdown-selected) {
  div > div {
    &.wrapContainer {
      margin-left: 0;
    }
  }
}

.wrapContainer {
  display: flex;
  justify-content: space-between;
  width: inherit;
  margin-left: 24px;

  .tooltipIconSchedule {
    color: $geodocs--locked-content-color;
    margin-left: 5px;
  }

  .selectedTaskTeam {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 992px) {
  .selectedProgramme {
    font-size: 12px;
  }
  .selectedProject {
    font-size: 12px;
  }
}
