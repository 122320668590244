@import '../../../styles/Variables';
.container {
  font-weight: 400;
  font-size: 14px;
  height: 100%;
  width: 100%;
  padding-left: 12px !important;
}

.disabled {
  cursor: not-allowed;
  color: $geodocs--button--disabled--color;
}

.icon {
  font-size: 16px;
}

@media screen and (max-width: 992px) {
  .container {
    font-size: 12px;
  }
}
