@import '../../../styles/Variables';

.expandable {
  display: flex;
  div:global(.search-box-container) {
    div:global(.suggestions-wrapper) {
      position: relative;

      ul:global(.suggestions) {
        margin: 0;
      }
    }
  }
}

.formInput {
  width: 50%;
}

.secondFormInput {
  padding-left: 10px;
}