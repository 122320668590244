@import '../../../styles/Variables';

.searchBoxContainer {
  margin-top: 15px;
}

.userPill {
  margin-right: 8px;
  margin-bottom: 8px;

  & + .searchBoxContainer {
    margin-top: 7px;
  }
}

.label {
  margin-top: 20px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  display: block;
}

.required {
  position: relative;
  color: $geodocs--user-selector--required--color;
  margin-left: 8px;
  vertical-align: sub;
}
