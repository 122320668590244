@import '../../../../styles/Variables';

.dropdown {
  position: relative;
  display: flex;
  margin-right: 16px;

  .expandBtn {
    border: 1px solid;
    border-color: $geodocs--add-content--dropdown--action-bar--export-btn--border-color;
    background-color: $geodocs--add-content--dropdown--action-bar--export-btn--background-color;
    box-sizing: border-box;
    color: $geodocs--add-content--dropdown--action-bar--export-btn--color;
    display: flex;
    align-items: center;
    margin-left: 16px;
    position: relative;
    justify-content: center;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    height: 40px;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;

    .itemsContainer {
      position: absolute;
      top: 17px;
      left: 0;
      width: 230px;

      .dropdownOptionItem {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .dropdownButtonIcon {
          padding-right: 10px;
          width: 30px;
          height: 30px;
        }

        span {
          width: 230px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .divider {
        width: 100%;
        border-bottom: 1px solid $geodocs--add-content--dropdown--action-bar--divider--color;
        margin: 2px 0;
      }
    }

    .btnMenu {
      display: flex;
      flex-direction: row !important;
      height: 40px;
      align-items: center;
      padding-left: 20px;
      padding-right: 6px;
      z-index: 20;
    }

    .expandBtnIcon {
      font-size: 21px;
      height: 100%;
      display: contents;

      i {
        margin-right: 8px;
      }
    }
  }
}

.dropdownMenu li {
  text-decoration: none;
  padding: 12px 20px;
  font-size: 14px;
}

.dropdownMenuList li {
  position: relative;
}

.dropdown li:hover {
  background-color: $geodocs--dropdown-list--hover--background-color;
}

.dropdownMenu {
  background: $geodocs--dropdown-list--background-color;
  position: absolute;
  cursor: pointer;
  top: 40px;
  left: 0;
  width: 221px;
  box-shadow: 0px 0px 4px rgba(18, 18, 18, 0.25);
  z-index: $geodocs--dropdown-list--z-index;
  > :nth-child(1) {
    border-bottom: 1px solid #dddddd;
    padding: 4px 0;
  }
  > :nth-child(6) {
    border-top: 1px solid #dddddd;
    padding: 4px 0;
  }
}

.dropdownMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdownMenuList .dropdownSubmenuList {
  display: none;
  position: absolute;
  background: $geodocs--dropdown-list--background-color;
  width: 221px;
  left: 100%;
  top: -4px;
  box-shadow: 0px 0px 4px rgba(18, 18, 18, 0.25);
  padding: 4px 0;
  transform: translateY(0);
}

.dropdownMenuList {
  & > li:hover {
    & > .dropdownSubmenuList {
      display: block;
    }
  }
}

.dropdownItem {
  display: flex;
  align-items: center;
}

.iconButton {
  margin-right: 0.5rem;
}

.iconRight {
  margin-left: 50px;
  margin-top: 4px;
}
