@import '../../../../styles/Variables';

.container {
  display: flex;

  .uploadColumn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 24px;
    width: 100%;
    .uploadHeader {
      order: -1;
      flex: 1 0 100%;
    }
  }
}

.native {
  color: $geodocs--native--file--icon--color;
}

.supersedeContainer {
  display: initial;

  .uploadColumn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 24px;
    width: 100%;
    .uploadHeader {
      order: -1;
      flex: 1 0 100%;
    }
  }
}

.header {
  font-size: 24px;
  margin: 24px 0 6px 24px;
  font-weight: 700;
}

.metadataWrapper {
  width: auto;
  flex-direction: row;
}

.uploadWrapper {
  width: auto;
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  padding-right: 20px;
}

.rightContainer {
  display: flex;
  flex-direction: column;
}

.uploadedFiles {
  display: flex;
  width: 100%;
}

.uploadedFilesContainer {
  background-color: $geodocs--upload-form--background-color;
  box-sizing: border-box;
  padding: 24px;
  overflow: auto;
  position: relative;
  grid-area: content;
  width: 100%;

  .uploadedFileHeading {
    display: flex;
    align-items: center;
    padding-bottom: 24px;

    .numberUploadFiles {
      font-size: 14px;
      font-weight: bold;
    }

    .actionButtons {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 16px;
      }

      .dropDownAdjustableColumns {
        margin-left: 8px;
        height: 41px;
      }
    }
  }

  .uploadedFilesEnd {
    padding: 24px 0 0;

    .totalFiles {
      font-size: 14px;
      color: $geodocs--button--disabled--color;
    }
  }

  .filesTable {
    max-height: 660px;
    min-height: 310px;
    overflow-x: scroll;

    div:global(.table-resize-handle) {
      max-height: 80px;
    }

    & thead {
      position: sticky;
      top: 0;
      z-index: 10;
    }

    .fileSizeHeader {
      margin: 0 70px;
    }

    td.supersedeCell {
      background-color: $geodocs--upload-form--dropdown--disabled-background-color !important;
      color: $geodocs--upload-form--supersede--metadata-color;
    }
    td.textCell {
      .overFlow {
        display: flex;
        word-break: break-all;
      }
    }

    .status {
      padding: 6px 12px;
      border-radius: 4px;
      font-size: 11px;
      float: right;
      width: max-content;
      margin-bottom: 5px;

      &.ready {
        color: $geodocs--upload-file-ready-status--color;
        border: 1px solid $geodocs--upload-file-ready-status--color;
        background-color: $geodocs--upload-file-ready-status--background;
      }

      &.duplicate {
        color: $geodocs--upload-file-duplicate-status--color;
        border: 1px solid $geodocs--upload-file-duplicate-status--color;
        background-color: $geodocs--upload-file-duplicate-status--background;
      }

      &.missingMetadata {
        color: $geodocs--upload-file-duplicate-status--color;
        border: 1px solid $geodocs--upload-file-duplicate-status--color;
        background-color: $geodocs--upload-file-duplicate-status--background;
      }

      &.supersede {
        color: $geodocs--upload-file-supersede-status--color;
        border: 1px solid $geodocs--upload-file-supersede-status--color;
        background-color: $geodocs--upload-file-supersede-status--background;
      }

      &.missingFileExtension {
        color: $geodocs--upload-file-duplicate-status--color;
        border: 1px solid $geodocs--upload-file-duplicate-status--color;
        background-color: $geodocs--upload-file-duplicate-status--background;
      }
    }

    td:first-child {
      border-left: 0;
    }

    td:last-child {
      position: sticky;
      right: 0;
      background-color: $geodocs--file-table--action-column--background-color !important;
      border-right: 1px solid $geodocs--white;
      border: 1px;

      &:hover {
        z-index: 10;
      }
    }

    .fileWrapper {
      display: flex;
      align-items: center;

      .fileIcon {
        padding-left: 15px;
        transform: scaleY(-1);
      }

      span {
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;
        padding-left: 15px;
        top: 2px;
        word-break: break-all;
      }
    }
  }
}

.fromWrapper {
  display: flex;
  flex-direction: column;
}

.cardHeader {
  height: 90px;
  display: flex;
  justify-content: space-between;
}

.uploadForm {
  display: flex;
  flex-direction: column;
  background: $geodocs--upload-form--background-color;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.wrapperFileDropZone {
  width: 100%;
  padding: 0;
}

.uploadButtons {
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 65px;
}

.dropdown {
  margin-bottom: 15px;
}

.input {
  margin-top: 5px;
}

.validationError {
  width: 100%;
  text-align: center;
  color: $geodocs--upload-form--wrong-file-extension-text--color;
  padding: 0 24px;
}

.visibleToTeams {
  margin: 20px 0;

  .visibleToTeamsIcon {
    margin-left: 10px;
  }

  .visibleToTeamsTooltip {
    padding-right: 80px;

    .visibleToTeamsTooltipContent {
      max-height: 300px;
      overflow-y: scroll;
    }

    p {
      overflow-wrap: break-word;
      word-break: break-all;
      margin: 0;
    }

    .title {
      color: $geodocs--upload-form--visibleToTeamsTooltip--title-color;
      font-size: 12px;
    }

    .shared {
      border-left: 3px solid $geodocs--upload-form--visibleToTeamsTooltip--shared-color;
      padding-left: 5px;
    }

    .published {
      border-left: 3px solid $geodocs--upload-form--visibleToTeamsTooltip--published-color;
      padding-left: 5px;
    }

    .publishedMarginTop {
      margin-top: 10px;
    }

    .currentDeliveryTeamIcon {
      color: $geodocs--upload-form--visibleToTeamsTooltip--currentDeliveryTeamIcon-color;
      font-size: 14px;
    }
  }
}
