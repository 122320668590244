@import '../../../styles/Variables';

.iconButton {
  width: 40px;
  height: 40px;
  color: $geodocs--button-icon-secondary--color;
  background-color: $geodocs--button-icon-secondary--background-color;
  border-color: $geodocs--button-icon-secondary--border-color;
  display: inline-flex;
  align-items: center;

  &:hover:enabled {
    color: $geodocs--button-icon-secondary--hover--color;
    background-color: $geodocs--button-icon-secondary--hover--background-color;
    border-color: $geodocs--button-icon-secondary--hover--border-color;
  }

  &:focus:enabled {
    color: $geodocs--button-icon-secondary--focus--color;
    background-color: $geodocs--button-icon-secondary--focus--background-color;
    border-color: $geodocs--button-icon-secondary--focus--border-color;
  }

  &:active:enabled {
    color: $geodocs--button-icon-secondary--active--color;
    background-color: $geodocs--button-icon-secondary--active--background-color;
    border-color: $geodocs--button-icon-secondary--active--border-color;
  }
}
