@import '../../../styles/Variables';

.historyItemDetail {
  margin-left: 32px;
  font-size: 14px;
  width: calc(100% - 110px);
}

.historyItemDetailTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;

  span + span {
    margin-left: 8px;
  }
}

.historyItemDetailDate {
  color: $geodocs--task-information-history-item--date--color;
  font-size: 11px;
}

.historyItemDetailMessage {
  background-color: $geodocs--task-information-history-item--message--background-color;
  padding: 16px;
  width: 100%;
  margin-top: 24px;
}
