@import '../../../styles/Variables';

.goBack {
  background-color: transparent;
  border-style: none;
  font-size: 12px;
  font-weight: 700;
  color: $geodocs--go-back-button--text--color;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  height: auto;
  margin: 35px 0 0 30px;

  & > i {
    color: $geodocs--go-back-button--arrow--color;
    font-size: 12px;
    margin-right: 8px;
  }

  &:hover:enabled,
  &:focus:enabled {
    background-color: transparent;
    color: $geodocs--go-back-button--text--hover--color;

    & > i {
      color: $geodocs--go-back-button--arrow--hover--color;
    }
  }
}
