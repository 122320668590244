@import '../../../../styles/Variables';

.searchBoxContainer {
  margin-top: 15px;
  position: relative;
  z-index: 10;

  .searchBox {
    ul:global(.suggestions) {
      max-height: 20rem;
    }
  }
}

.userPill {
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: $geodocs--add-transmittal-message-notify-selector--user-pill--background-color;
  color: $geodocs--add-transmittal-message-notify-selector--user-pill--color;

  b {
    margin-left: 8px;
  }
  & + .searchBoxContainer {
    margin-top: 7px;
  }
}

.userIcon {
  float: right;
  i {
    margin-right: 10px;
  }
}

.label {
  margin-top: 20px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  display: block;
}

.required {
  position: absolute;
  color: $geodocs--user-selector--required--color;
  margin-left: 8px;
  margin-top: 4px;
}

.disable {
  color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}
.listSeparator {
  color: $geodocs--add-transmittal-message-notify-selector--disable--color;
  pointer-events: none;
  align-items: center;
  display: flex;
  margin-top: 5px;
  position: relative;

  &:before {
    background-color: rgba(0, 0, 0, 0.25);
    content: '';
    height: 1px;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.userCount {
  float: right;
}
