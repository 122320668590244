@import '../../../styles/Variables';

.dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 9px;
  vertical-align: middle;
  background-color: $geodocs--dot--background-color;
}
