@import '../../../styles/Variables';

.button {
  width: -webkit-fill-available;
  cursor: pointer;
  display: flex;
  text-align: left;
  align-items: center;
  border: none;
  background-color: $geodocs--sidebar-button--background-color;
  user-select: none;
  color: $geodocs--sidebar-button--color;
  font-weight: 400;
  text-decoration: none;
  font-size: 16px;
  height: 3rem;
  padding-left: 1.5rem;

  i {
    padding-right: 10px;
    font-size: 16px;
    color: $geodocs--sidebar-button--icon--color;
  }

  &.isActive {
    i {
      color: $geodocs--sidebar-button--active--color;
    }
    padding-left: 1.25rem;
    border-left: 0.25rem solid $geodocs--sidebar-button--active--border-left-color;
    background-color: $geodocs--sidebar-button--active--background-color;
  }

  &:hover {
    i {
      color: $geodocs--sidebar-button--hover--icon--color;
    }
  }
}
