@import '../../../styles/Variables';

.taskCard {
  margin: 32px 0;
  background-color: $geodocs--task-card--background-color;
  padding: 24px;
  font-size: 14px;
  font-weight: 400;
}

.viewTaskButton {
  float: right;

  &:hover:enabled {
    color: $geodocs--black;
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItemInline {
  display: inline-block;

  & + & {
    margin-left: 48px;
  }
}

.listItemDivider {
  border: 1px solid $geodocs--divider--color;
  border-left-style: none;
  border-right-style: none;
  border-top-style: none;
}

.label {
  font-weight: 700;

  & + span {
    margin-left: 16px;
  }
}

.marginBottom16px {
  margin-bottom: 16px;
}

.message {
  white-space: pre-wrap;
}
