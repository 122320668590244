@import '../../../styles/Variables';

.heading {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 24px 35px;

  .transmittalSubject {
    display: inline-block;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }

  .transmittalTitle {
    label {
      color: $geodocs--user-circle--tool-tip--email--color;
      font-size: 12px;
      line-height: 18px;
    }

    span {
      font-size: 14px;
      color: $geodocs--black;
      line-height: 21px;
    }
  }
}

.transmittalHeader {
  display: flex;
  flex-direction: column;
  padding: 0px 24px;

  .initialLine {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 14px;

    .totalVisibility,
    .visibilityUsers {
        font-size: 14px;
        line-height: 18px;
      }

    .left{
      display: flex;
      align-items: center;
      gap: 16px;

      .category {
        background: $geodocs--transmittal-details-disabled-color;
        border-radius: 4px;
        color: $geodocs--black;
        display: flex;
        align-items: center;
        padding: 9.5px 15px;
        font-size: 14px;
        line-height: 11px;
      }
    }

    .right {
      align-items: center;
      display: flex;
      margin-left: auto;
      gap: 10px;
    }
  }
}

.panelLabel {
  display: flex;
  flex-direction: column;

  .messageSummaries {
    align-items: center;
    display: flex;
    gap: 26px;
    height: 40px;

    .leftLabel {
      display: flex;
      align-items: center;
      gap: 26px;

      > span {
        align-items: center;
        color: $geodocs--transmittals-action-bar--filter-label--color;
        display: flex;
        font-weight: normal;
        gap: 10px;
      }
    
      .disabledUserCircle {
        background: $geodocs--transmittal-details-disabled-color;
        border: 1px solid $geodocs--white;
        color: $geodocs--user-circle--tool-tip--email--color;
      }
      &.disabled {
        .detailIcon,
        .detailIcon:hover {
          color: $geodocs--black;
        }
      }
    }

    .rightLabel {
      display: flex;
      margin-left: auto;
      margin-right: 16px;
    }
  }

  .messageSubject {
    display: flex;
    line-height: 18px;
    size: 12px;
    height: 31px;
    align-items: center;
  }

  .messageMessage {
    font-size: 12px;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.actionPanel {
  display: flex;
}

.btnIcon {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 8px;
}

.detailsPanel {
  .panel {
    padding: 0 40px;
    font-size: 14px;
    height: calc(100% - 245px);
    overflow-y: auto;
    flex-grow: 1;

    hr {
      border-top: 1px solid $geodocs--transmittal-details--panel--border-color;
    }
  }

  .fileDetails {
    position: relative;
    border-left: 4px solid $geodocs--transmittal-details--panel--border-color;
  }
}

.transmittalDetailAccordion {
  div:global(.accordion-panel.accordion-panel-active) {
    border-left: 4px solid $geodocs--warning;
    border-bottom: 0;
  }
  
  div:global(.accordion-panel.accordion-panel-active) + div:global(.accordion-content) {
    border-left: 4px solid $geodocs--warning;
    border-bottom: 0;
  }

  div:global(.heading-label-wrapper) {
    width: calc(100% - 40px);
  }

  div:global(.icon-label-wrapper) {
    height: 40px;
    display: flex;
    align-items: center;
  }

  div:global(.accordion-panel) {
    &.responsePanelHeader {
      padding-top: 10px;
      padding-bottom: 10px;
      align-items: inherit;
      max-width: 100%;
      .rightLabel {
        font-size: 14px;
        font-weight: normal;
        color: $geodocs--black;
      }
      
    }

    &.responsePanelHeader:hover {
      .messageSubject,
      .messageMessage {
        color: $geodocs--black;  
      }
    }
  }
}

.contentSubject {
  font-size: 14px;
  font-weight: 700;
  color: $geodocs--transmittals-action-bar--filter-label--color;
  line-height: 21px;
}

.contentMessage {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  p {
    font-size: 14px;
  }
}

.iconWrapper {
  display: flex;
  margin-left: 24px;
}

.closed {
  color: $geodocs--transmittals-item--closed--background-color;
}

.flagged {
  font-size: 18px;
  color: $geodocs--transmittal-details--flagged--color;
  margin-left: 14px;
}

.overdue {
  background-color: $geodocs--transmittal-details--overdue--background-color;
}

.fields {
  list-style: none;
  padding: 0;
}

.label {
  font-weight: 700;
  display: flex;
  align-items: center;
}

.fieldValue {
  display: flex;
  align-items: center;
  height: 50px;
  gap: 16px;
}

.initiatorName {
  display: flex;
  align-items: center;
  gap: 8px;
}

.message {
  margin: 32px 0;
  p {
    display: inline;
    font-size: 14px;
  }
}

.detailIcon {
  font-size: 16px;
}

.viewFileWrapper {
  display: flex;
  justify-content: flex-end;

  button {
    i {
      font-size: 16px;
      position: relative;
      top: 3px;
      right: 5px;
    }
  }
}
