.loginPage {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.5) 25%,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(0, 0, 0, 0) 80%
    ),
    url('../../../assets/login-background.jpg');
  background-size: cover;
  background-position: right;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;

  @media screen and (min-width: 1440px) {
    background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.5) 25%,
        rgba(0, 0, 0, 0.4) 50%,
        rgba(0, 0, 0, 0) 80%
      ),
      url('../../../assets/login-background.jpg');
  }

  .loginContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0 2rem 0 2rem;

    @media screen and (min-width: 576px) {
      margin: 0 2rem 0 2rem;
    }

    @media screen and (min-width: 768px) {
      margin: 0 4.5rem 0 4.5rem;
    }

    @media screen and (min-width: 992px) {
      margin: 0 9rem 0 9rem;
    }
  }

  .leftGradient {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(18, 18, 18, 0.5) 5.73%,
      rgba(18, 18, 18, 0.3) 58.33%,
      rgba(18, 18, 18, 0) 100%
    );
  }

  h1 {
    font-weight: 400;
    font-size: 4.5rem;
    line-height: 0em;
  }

  h2 {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1em;
  }

  p {
    padding-top: 2rem;
    margin-bottom: 3em;
    font-size: 1rem;

    @media screen and (max-width: 375px) {
      font-size: 0.88rem;
    }

    @media screen and (max-width: 320px) {
      font-size: 0.8rem;
    }
  }

  button {
    margin-right: 1.25rem;
  }

  .textOnly {
    border: none;
    outline: none;
    background-color: transparent;
  }

  .header {
    padding: 2rem 0px;
    flex: 1 1;
    max-width: 90%;
    display: flex;
    align-items: flex-end;

    svg {
      max-width: 90%;
    }
    .logo {
      height: 110px;
    }
    @media screen and (min-width: 576px) {
      width: 25rem;
    }
  }

  .content {
    flex: 1 1;
    width: 100%;

    @media screen and (min-width: 576px) {
      width: 25rem;
    }

    svg {
      height: 4.125rem;
      width: auto;

      @media screen and (max-width: 375px) {
        height: 2.125rem;
      }

      @media screen and (max-width: 320px) {
        height: 2rem;
      }
    }

    @media screen and (min-width: 576px) {
      width: 25rem;
    }
  }

  .footer {
    padding-bottom: 4.5rem;
    flex: 0;

    .tucanaLogo {
      float: right;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: 375px) {
      padding-bottom: 2.5rem;
    }

    @media screen and (max-width: 320px) {
      padding-bottom: 0rem;
    }
  }
}
