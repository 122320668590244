@import '../../../styles/Variables';

.button {
  color: $geodocs--drop-down-menu-button--color;
  background-color: transparent;
  border-style: none;
  width: 100%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;

  & + button {
    margin-left: 0px;
  }

  &:hover:enabled,
  &:focus:enabled {
    color: $geodocs--drop-down-menu-button--hover--color;
    background-color: $geodocs--drop-down-menu-button--hover--background-color;
  }
}
