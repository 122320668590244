@import '../../../../Variables';

.gridGroupWrapper {
  margin-top: 24px;
  size: 20px;

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: $geodocs--system-file-templates-header--color;
    font-family: 'Fira Sans';
  }

  .subHeader {
    font-family: 'Arial';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .uploadFileBtn {
    width: 100%;
    margin-bottom: 24px;
  }

  .actionButtonsWrapper {
    display: flex;
  }
}

.gridTableWrapper {
  tr:global(.header-divider) {
    display: flex !important;
  }
  td:global(.table-cell) {
    padding-left: 0 !important;
  }

  .droppableWrapper {
    width: 99.75%;

    .appRowWrapper {
      display: flex;
      width: 100%;
      background-color: $geodocs--system-file-templates-table-row--background-color;
      margin: 10px 0;
      align-items: flex-start;

      .row {
        padding: 12px;
        padding-left: 0;
        font-size: 14px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box; /* Opera/IE 8+ */
        display: flex;
        align-items: center;
        line-height: 36px;

        &.textDisabled {
          background-color: none;
          span {
            color: $geodocs--system-file-templates-table-cell--span--disabled--color;
          }
        }
        .wrapTemplateName {
          display: block;
          width: 100%;

          .charactersLeft {
            font-weight: 400;
            font-size: 12px;
            float: right;
            color: $geodocs--system-file-templates-table-cell--span--disabled--color;
          }
        }

        .templateFileName {
          padding-left: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .originalFileName {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .downloadBtn,
        .removeBtn {
          background-color: $geodocs--system-file-templates--icon-background-color;
          border: none;

          .downloadIcon,
          .removeIcon {
            color: $geodocs--system-file-templates--icon-color;
            width: 24px;
            height: 24px;
          }
        }
      }

      .rowTemplateFileName {
        padding: 0;
        > span {
          padding: 12px 0;
        }
      }
      .tooltip {
        height: 15px;
        margin-top: 22px;
      }

      .dragIcon {
        justify-content: space-between;
      }

      .textOverflow {
        text-overflow: ellipsis;
        white-space: pre-wrap;
        overflow: hidden;

        > span {
          text-overflow: ellipsis;
          white-space: pre-wrap;
          overflow: hidden;
          display: block;
        }
      }
    }
  }
}
