@import '../../../styles/Variables';

.charactersLeft {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  color: $geodocs--ProjectManagement-add-project-title-color;
  font-size: 12px;
  margin-top: 13px;
}
