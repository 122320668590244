.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 400px;

  & > * + * {
    margin-left: 10px;
  }

  div:global(.search-box-container .field-wrapper .input-field input) {
    font-size: 12px;
    padding-right: 12px;
  }
}
