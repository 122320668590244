@import '../../../../styles/Variables';

.errorMessage {
  margin-top: 24px;
  color: $geodocs--review-modal--error-message--color;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.errorMessageIcon {
  margin-right: 8px;
}
