@import '../../../styles/Variables';

.panel {
  padding-left: 24px;
  box-sizing: border-box;
}

.comments {
  margin-bottom: 16px;
  flex: 1;
}

.buttons {
  display: flex;
  align-items: center;
}

.button,
.reassignButton {
  width: 125px;
}

@media (min-width: 1500px) {
  .iconButton {
    display: none;
  }
}

@media (max-width: 1500px) {
  .panel {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    width: 100%;
    padding: 0;
    flex: 0 0 100%;
  }

  .button {
    display: none;
  }

  .reassignButton {
    line-height: 24px;
  }

  .iconButton,
  .reassignButton {
    margin-left: 10px;
  }
}
