@import '../../../Variables';
.deliveryTeamContainer {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  height: 100%;
  overflow: auto;

  div:global(.tabs-wrapper .tab-list-active) {
    background-color: $geodocs--button--background-color;
    > span {
      color: $geodocs--white;
    }
  }
  div:global(.tabs-wrapper .tab-list-active::after) {
    background-color: $geodocs--button--background-color;
  }
  div:global(.tabs-wrapper.tabs-medium .tab-list-item) {
    width: 33.333%;
    border: 1px solid $geodocs--button--background-color;
    text-align: center;
    > span {
      width: 100%;
    }
  }

  .heading {
    color: $geodocs--team-management-heading--color;
    padding: 0 0 24px;
    font-size: 24px;
    width: 100%;
    display: flex;
    flex-grow: 1;

    .title {
      width: 80%;
    }

    .buttons {
      width: 20%;
      display: flex;
      justify-content: flex-end;

      .editIcon {
        cursor: pointer;
        position: relative;
        top: 3px;
      }
    }
  }
  .header {
    padding: 0 0 24px;

    .teamInfo {
      padding: 10px 0;

      .row {
        width: 100%;

        &.label {
          font-size: 14px;
          line-height: 21px;
          color: $geodocs--teamManagement-noticed-text-color;
          font-weight: bold;
          padding: 0 0 16px;
        }
        .actionButton {
          margin-left: 16px;
        }
      }
    }
  }

  .deliveryTeamSettingTab {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .customTab {
    width: 32%;
  }
}
