@import '../../../../Variables';

.systemUserTable {
  overflow: auto;
  min-height: 500px;

  table {
    width: 100%;

    .deactiveUserBtn,
    .removeUserBtn {
      background-color: $geodocs--system-users-table--deactive-user-btn-color;
      border: none;

      .deactiveUserIcon,
      .removeUserIcon {
        color: $geodocs--system-users-table--deactive-user-icon-color;
      }
    }
  }
  thead {
    :global {
      tr.table-row.is-header {
        > td.table-cell {
          white-space: nowrap;
        }
      }
    }
  }
}

.tableHeadColumn {
  width: fit-content;
}

.paginationWrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.paginationContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  .paginationFlexGrow {
    position: absolute;
    right: 48px;
    padding-top: 0.9375rem;
  }
}
