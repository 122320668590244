@import '../../../styles/Variables';

.contextMenu {
  margin-top: 40px;
}

.overflowButton {
  margin-left: 16px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $geodocs--delete-overflow-button--button--background-color;
  border: 1px solid $geodocs--delete-overflow-button--button--border-color;
  > i:global(.overflow-menu-button) {
    background-color: transparent;
    color: $geodocs--delete-overflow-button--button--border-color;
  }
  &:hover {
    background-color: $geodocs--delete-overflow-button--button--border-color;
    > i:global(.overflow-menu-button) {
      background-color: transparent;
      color: $geodocs--delete-overflow-button--button--background-color;
    }
  }
  &:global(.is-open) {
    background-color: $geodocs--delete-overflow-button--button--border-color;
    > i:global(.overflow-menu-button) {
      background-color: transparent;
      color: $geodocs--delete-overflow-button--button--background-color;
    }
  }
  &:global(.is-disabled) {
    background-color: $geodocs--delete-overflow-button--button--disabled--background-color;
  }
  div:global(.overflow-menu-options) {
    padding: 16px 0px;
    background-color: $geodocs--delete-overflow-button--dropdown--background-color;
    box-shadow: 0px 0px 4px $geodocs--drop-down-menu--box-shadow--color;
    div:global(.overflow-menu-item) {
      &:hover {
        background-color: $geodocs--delete-overflow-button--dropdown--hover--background-color;
        color: $geodocs--delete-overflow-button--dropdown--hover--color;
      }
    }
  }
}
