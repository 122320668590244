@import '../../../styles/Variables';

.detailFilesModalContainer {
  width: 993px;
  max-height: 90vh;
  max-width: 90vw;

  .wrapper {
    display: flex;
    flex-grow: 1;
    max-height: calc(90vh - 120px);

    .fileDetails {
      position: relative;
      border-left: 4px solid $geodocs--task-information-details--panel--border-color;
    }
  }

  .tableFiles {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
  }

  .headerTitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 24px;
    margin: 0;
  }

  .headerIcon {
    padding-right: 28px;
    position: relative;
    top: 3px;
  }

  .modalActions {
    margin-top: 0px;
  }

  .accordionWrapper {
    max-height: 746px;
    overflow-y: auto;
    margin: 0;

    .accordionHeading {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    button.downloadAllButton {
      margin: 24px 0 0 0;
      float: right;
      color: $geodocs--button-secondary--color;
      border: 1px solid $geodocs--button--background-color;
      background-color: $geodocs--button-secondary--background-color;

      &:hover:enabled:not(.is-loading) {
        color: $geodocs--transmittals-details-filedetails-downloadallbutton;
      }
    }

    .fileSize {
      display: inline-block;
    }
  }
}

.fileCell {
  display: flex;
  align-items: center;
  .fileIcon {
    transform: scaleY(-1);
    padding: 0 8px 0 0;
  }
}

.native {
  margin-left: 5px;
  color: $geodocs--native--file--icon--color;
}
