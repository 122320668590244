@import '../../../../styles/Variables';

.legendWidgetContanier {
  bottom: 65px;
  position: relative;
  left: 20px;
  button {
    i {
      margin: unset;
    }
  }
}

.expand {
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 17px;
  left: 0px;
  width: 250px;
  background-color: $geodocs--visualisation--legend-widget--expand--background-color;
}

.legendWidgetPanel {
  display: none;
}

.legendContainer {
  top: 50px;
  display: flex;
}
