@import '../../../styles/Variables';

.assigneeModal {
  .assignBy {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid $geodocs--divider--color;

    .label {
      font-weight: bold;
      padding-right: 24px;
    }
  }

  .assigneeList {
    padding: 16px 0 0;
    overflow-y: auto;
    max-height: calc(100vh / 2);

    .assignee {
      display: flex;
      align-items: center;
      padding: 8px 0 0;

      .status {
        display: flex;
      }
    }
  }

  .userIcon {
    display: flex;
    align-items: center;
    width: 50%;

    .fullName {
      margin-left: 8px;
    }
  }
}
