@import '../../../styles/Variables';

.userTooltip {
  display: none;
  position: absolute;
  box-shadow: 0px 0px 3px $geodocs--user-circle--tool-tip--box-shadow;
  background-color: $geodocs--user-circle--tool-tip--background-color;
  color: $geodocs--user-circle--tool-tip--color;
  padding: 12px;
  box-sizing: border-box;
  margin-top: 28px;
  align-self: flex-start;
  z-index: $geodocs--user-circle--tool-tip--z-index;
  white-space: nowrap;
}

.userEmail {
  color: $geodocs--user-circle--tool-tip--email--color;
}

.user {
  position: relative;
  width: 31px;
  height: 31px;
  background-color: $geodocs--user-circle--background-color;
  border: 0.62px solid $geodocs--user-circle--border-color;
  box-sizing: border-box;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: $geodocs--user-circle--color;

  & + & {
    margin-right: -4px;
  }

  &:hover {
    background-color: $geodocs--user-circle--hover--background-color;
    .userTooltip {
      display: block;
    }
  }
}
