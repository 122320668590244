@import '../../../../Variables';

.pageHeading {
  border-bottom: 0;
  padding-left: 0;

  .pageHeadingLabel {
    font-size: 20px;
    font-weight: 400;
    color: $geodocs--system-users-list--page-heading-label-color;
  }
}

.addForm {
  padding: 0px 46px 16px 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .label {
    font-weight: 700;
    font-size: 14px;
  }

  .requiredSymbol {
    color: $geodocs--add-transmittal-response--required-symbol--color;
  }

  .userEmailInput,
  .displayNameInput {
    width: 35%;
  }

  .inputError {
    color: $geodocs--user-selector--required--color;
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 8px;
  }

  .roleInput {
    width: 23%;
  }

  .labelBtn {
    visibility: hidden;
  }
}
