@import '../../../styles/Variables';

.label {
  display: flex;
  column-gap: 12px;
  font-weight: 400;
  color: $geodocs--selected-files-label--color;
  margin-left: 16px;
  font-size: 14px;
}
.linkLabel {
  cursor: pointer;
  font-size: 14px;
  color: $geodocs--selected-files-link-label--color;
}
