@import '../../../styles/Variables';

.supportingFiles {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  margin: 24px 0 12px 0;

  .supportingFilesAccordion {
    .accordionHeading {
      display: flex;
      align-items: center;

      svg {
        margin: 0 16px 0 0;
      }
    }
  }
}
