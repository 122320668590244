.cellInputRight {
  padding: 0 0 0 8px;
}

.cellInputLeft {
  padding: 0 8px 0 0;
}

.modalActions {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    height: 1px;
    width: 200%;
    background-color: rgba(0, 0, 0, 0.25);
  }
  margin-top: 24px;
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;

  button {
    margin-left: 12px;
  }
}
