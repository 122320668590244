@import '../../../../styles/Variables';

.item {
  border: 1px solid $geodocs--file-information-history-release--border--color;
  padding: 24px;

  div:global(.accordion-panel),
  div:global(.accordion-content) {
    padding: 0 !important;
    border: none !important;
  }

  & > div + div {
    margin-top: 10px;
  }

  .revisionLabel {
    display: flex;
  }

  .uploadIcon {
    margin-top: 3px;
    margin-right: 5px;
    font-size: 15px;
  }

  .downloadAll {
    margin-top: 10px;
  }
}

.showMoreButton,
.showLessButton {
  padding: 10px 0;
  font-size: 14px;
  color: $geodocs--button-secondary--color;
  cursor: pointer;
}

.revision {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}

.suitability {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin: 0 0 10px 0;
}

.fileHistoryItem {
  background-color: $geodocs--file-information-history-upload--background-color;
  padding: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $geodocs--file-information-history-upload--color;
  margin-bottom: 24px;
  margin-top: 24px;

  div:global(.accordion-panel),
  div:global(.accordion-content) {
    padding: 0 !important;
    border: none !important;
  }
}
.icon {
  margin-top: 3px;
  margin-right: 5px;
  font-size: 15px;
}
