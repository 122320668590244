@import '../../../../styles/Variables';

.container {
  margin-top: 20px;
  float: right;
  .teamVisible {
    display: inline-block;
    word-wrap: break-word;
    word-break: break-all;
  }
  .nonIndicator {
    padding-left: 20px;
  }
  .indicator {
    background-color: $geodocs--DeliveryTeam-status-indicator--background-color;
  }
}
