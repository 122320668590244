@import '../../../styles/Variables';

.pageFooter {
  width: 100%;
  min-height: 2.4375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px $geodocs--footer--border-top solid;
  background-color: $geodocs--footer--background-color;
  position: fixed;
  bottom: 0;

  .logo {
    svg {
      height: 0.8rem;
    }
  }

  .divider {
    padding: 0 0.625rem;
    position: relative;
    top: -1px;
  }

  .footerContent {
    font-size: 11px;
    a {
      font-size: 11px;
      color: $geodocs--link--color;
      text-decoration: none;

      &:hover {
        color: $geodocs--link--hover--color;
      }
    }
  }
}
