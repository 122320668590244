@import '../../../../styles/Variables';

.item {
  background-color: $geodocs--file-information-history-upload--background-color;
  padding: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $geodocs--file-information-history-upload--color;
  margin-top: 20px;
}

.icon {
  font-weight: 400;
  font-size: 14px;
  color: $geodocs--file-information-history-upload--icon--color;
}

.nativeMessage {
  color: $geodocs--file-information-history-upload--title--color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  margin: 0;
}

.currentNativeFileName {
  margin-top: 10px;
  font-weight: 600;
}

.message {
  color: $geodocs--file-information-history-upload--title--color;
  font-size: 14px;
  margin: 0;
  margin-left: 10px;
}

.previousNativeFilename {
  color: $geodocs--file-information-history-upload--title--color;
  font-size: 14px;
  margin: 0;
  margin-left: 24px;
}

.title {
  color: $geodocs--file-information-history-upload--title--color;
  align-items: center;
  justify-content: flex-start;
  overflow-wrap: anywhere;
}

.user {
  margin-left: 24px;
}
