@import '../../../styles/Variables';

.expandable {
  div:global(.search-box-container) {
    div:global(.suggestions-wrapper) {
      position: relative;

      ul:global(.suggestions) {
        margin: 0;
      }
    }
  }
}
