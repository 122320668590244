@import '../../../styles/Variables';

.panel {
  width: calc(100% - 431px);
  padding: 0 24px 0 0;
  box-sizing: border-box;
  list-style: none;
  margin: 0;

  .assignPanel {
    .column {
      display: inline-flex;
      flex-direction: row;
      align-items: flex-start;
      width: 50%;
      margin-top: 16px;
    }
  }
}

.detailPanel {
  .column {
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    width: 50%;
    margin-top: 16px;

    .columnValue,
    .workflowText {
      line-height: 31px;
    }

    .workflowText {
      display: block;
    }

    .workflowPill {
      display: none;
    }
  }
}

.divider {
  border: 1px solid $geodocs--divider--color;
  border-left-style: none;
  border-right-style: none;
  border-top-style: none;
  margin-top: 16px;
}

.hiddenSmall {
  display: none;
}

.fullWidthDivider {
  flex-basis: 100%;
}

.label {
  width: 120px;
  font-weight: 700;
  line-height: 31px;
}

.assignedToWrapper {
  display: flex;
  width: 100%;

  .assignedToLabel {
    width: 120px;
    font-weight: 700;
    display: flex;
    line-height: 31px;

    .requiredAllToolTipIcon {
      margin-left: 4px;
      .requiredIcon {
        color: $geodocs--treeview--expand-icon-color;
      }
      .requiredAllToolTipText {
        color: $geodocs--style-guide--search--clear-icon--color;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.message {
  white-space: pre-wrap;
  margin-bottom: 8px;
}

@media (min-width: 1500px) {
  .requiredAllToolTipIcon {
    display: none;
  }
}

@media (max-width: 1500px) {
  .panel {
    width: 100%;
    padding: 0;
    flex: 0 0 100%;

    .assignPanel {
      .column {
        align-items: center;

        .label {
          width: max-content;
          padding-right: 16px;
        }
      }
    }
  }

  .detailPanel {
    display: flex;

    .column {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: 25%;

      .workflowText {
        display: none;
      }

      .workflowPill {
        display: block;
      }

      .label {
        margin-bottom: 10px;
        flex: 0 0 100%;
      }
    }
  }

  .fullWidthDivider {
    display: none;
  }

  .column {
    align-items: center;
  }
}
