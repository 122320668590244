@import '../../../../styles/Variables';

.moveFileContainerModal {
  overflow: unset;

  .comboBox {
    margin-top: 40px;
    font-size: 14px;
    font-weight: 700;

    div:global(.input-field) > input {
      padding-right: 43px;
      text-overflow: ellipsis;
    }
  }
}
