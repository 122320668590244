@import '../../../styles/Variables';

.modalBody {
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .loader {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }

  .pdfContainer {
    height: 100%;
    width: 100%;

    :global {
      .react-pdf__Document {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;

        .react-pdf__Page {
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
          margin: 20px 0;
        }

        .react-pdf__message.react-pdf__message--error {
          display: flex;
          height: 100vh;
          align-items: center;
        }
      }
    }
  }

  button + button {
    margin-left: 0;
  }
}
