@import '../../../../styles/Variables';

.item {
  background-color: #fafafa;
  padding: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
}

.title {
  color: $geodocs--file-information-history-task--expanded-title--color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.link {
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.launch {
  font-size: inherit;
  vertical-align: middle;
  margin-left: 4px;
}

.events {
  padding: 0;
  margin: 0;
  list-style: none;

  & > li + li {
    margin-top: 20px;
  }
}
