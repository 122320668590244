@import '../../../../styles/Variables';

.item {
  background-color: $geodocs--file-information-history-task--background-color;
  padding: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $geodocs--file-information-history-task--color;
  cursor: pointer;
}

.titleContainer {
  color: $geodocs--file-information-history-task--title--color;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
}

.title {
  width: 240px;
}

.expandMore {
  font-size: 16px;
}
