@import '../../../../styles/Variables';

.container {
  padding: 0 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .supersedeHeaderIcon {
    font-size: 20px;
    padding-left: 10px;
  }

  .supersedeHeaderTooltip {
    font-size: 14px;
  }

  h1 {
    margin: 24px 0;
    font-size: 23px;
  }

  .filenameCell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .icon {
      font-size: 16px;
    }
  }

  .previewIcon {
    font-size: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .lockedContent {
    color: $geodocs--locked-content-color;
  }

  .searchContentWrapper {
    margin-left: auto;
    a {
      cursor: pointer;
    }
  }

  .searchMessage {
    font-size: 20px;
    text-align: center;
    position: relative;
    top: 100px;
  }
}

.filesTable {
  & thead {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .fileWrapper {
    display: flex;
    align-items: center;

    .fileIcon {
      transform: scaleY(-1);
    }

    span {
      position: relative;
      padding-left: 15px;
      top: 2px;
    }
  }
}

.paginationWrapper {
  bottom: 0;
  height: 130px;
  width: 100%;
}

.paginationContainer {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 52px;
  padding-top: 30px;
  .paginationFlexGrow {
    position: absolute;
    right: 48px;
    padding-top: 0.9375rem;
  }
}
