@import '../../../../../../styles/Variables';

.container {
  margin: 0 auto;
  width: 676px;
}

.dropzone {
  height: 234px;
}

%uploadForm {
  display: flex;
  flex-direction: column;
  padding: 23px 25px;
  background: $geodocs--system-file-templates-table-row--background-color;
  max-width: 636px;
}

.uploadForm {
  @extend %uploadForm;
}

.uploadButtons {
  @extend %uploadForm;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 65px;
}

.errorSummary {
  color: $geodocs--system-file-templates--upload-form--error-summaries-color;
}

.header {
  font-family: 'Fira Sans';
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}
.noteAddIcon {
  font-size: 40px;
  color: $geodocs--system-file-templates-noted-icon-color;
}
.highLight {
  color: $geodocs--system-file-templates--high-light--color;
  font-weight: 700;
}
.wrapFileName {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  padding: 12px;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid $geodocs--system-file-templates-file-name-border--color;
  display: flex;
  align-items: center;

  .closeIcon {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }
}

.wrapTemplateName {
  margin: 60px 0;

  .charactersLeft {
    font-weight: 400;
    font-size: 12px;
    float: right;
    line-height: 40px;
    color: $geodocs--system-file-templates-table-cell--span--disabled--color;
  }
}
