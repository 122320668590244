@import '../../../../styles/Variables';
.fileTables {
  .fileCell {
    display: flex;
    align-items: center;
    .fileIcon {
      transform: scaleY(-1);
      padding: 0 8px 0 0;
    }
  }
}

.filenameCell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filenameRemove {
  background-color: $geodocs--review-modal--filename-remove--background-color;
  border-style: none;
  width: 24px;
  height: 24px;
  padding: 4px;

  &:hover:enabled,
  &:focus:enabled {
    background-color: $geodocs--review-modal--filename-remove--hover--background-color;
  }
}

i.filenameRemoveIcon {
  color: $geodocs--review-modal--filename--remove-icon--color;
  font-size: 16px;
}
