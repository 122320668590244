@import '../../../../styles/Variables';

.overdue {
  background-color: $geodocs--transmittals-item--overdue--background-color;
}

.closed {
  background-color: $geodocs--transmittals-item--closed--background-color;
}

.open {
  background-color: $geodocs--transmittals-item--open--background-color;
}

.flagged {
  color: $geodocs--transmittals-item--flagged--background-color;
  line-height: 13px;
}

.buttonLink {
  padding: 0;

  .linkViewDetail {
    text-decoration: none;
    color: $geodocs--black;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 20px;
  }
}

.wordWrap {
  display: flex;
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
}

.overFlow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transmittalRow {
  td {
    cursor: pointer;
  }
}
