@import '../../../styles/Variables';

.navbar {
  background-color: $geodocs-nav-bar--background-color;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hamburger {
  border-style: none;
  border-radius: 50%;
  background-color: $geodocs-nav-bar--hamburger--background-color;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  margin-left: 10px;

  &:hover:enabled,
  &:focus:enabled {
    border-color: $geodocs-nav-bar--hamburger--hover--border-color;
    background-color: $geodocs-nav-bar--hamburger--hover--background-color;
  }
}

.logo {
  margin: 0 1vw;
  height: 40px;
}

.projectSelector {
  margin: auto;
  width: calc(100% - 440px);
  max-width: 750px;
}

.fill {
  flex-grow: 1;
}

.menuIcon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $geodocs-nav-bar--menu-icon--color;

  &.settingsIcon {
    background: none;
    border: none;
    margin: 0;
    width: 60px;
    height: 60px;

    i {
      font-size: 24px;
    }
  }
}

.menuItemsContainer {
  margin-top: 60px;
}

@media screen and (max-width: 1200px) {
  @media screen and (max-width: 992px) {
    .logo {
      display: none;
    }

    .projectSelector {
      margin-left: 15px;
      width: calc(100% - 210px);
    }

    .hamburger {
      margin-left: 15px;
    }

    .menuIcon {
      width: 40px;
      height: 40px;
      margin: 0 3px;
    }
  }
}
