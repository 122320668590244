.staticContent {
  font-family: Arial, Helvetica, sans-serif;
  padding: 80px 170px 80px 170px;
  width: auto;

  h1 {
    text-align: center;
    sup {
      font-size: 6px;
    }
  }

  sup {
    font-size: 4px;
  }

  .backToTopBtn {
    text-align: right;
  }

  .list {
    font-size: 16px;
    > li {
      font-weight: bold;
      margin-top: 48px;
    }
  }

  ul {
    margin-bottom: 32px;
    li {
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  .tableList {
    li {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
    }
  }
}
