@import '../../../../styles/Variables';

@mixin textWrapper {
  .textWrapper {
    padding-top: 10px;
    span {
      display: block;
      line-height: 30px;
      font-size: 14px;

      .onScreenIcon {
        position: relative;
        top: 10px;
        padding-right: 10px;
      }

      .keyboardIcon {
        position: relative;
        top: 5px;
        padding-left: 5px;
      }
    }
  }
}

.helpWidgetContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 30px;
  box-sizing: border-box;

  .navigationMenuWrapper {
    width: 100%;

    .heading {
      cursor: pointer;
      text-decoration: underline;
    }

    .goBackButton {
      margin: 10px;
    }

    .navigationWrapper {
      display: flex;
      width: 100%;

      .left {
        width: 40%;

        @include textWrapper();

        .right {
          width: 60%;
        }
      }
    }

    .mouseNavigationWrapper {
      display: flex;
      width: 100%;

      .left {
        width: 60%;

        @include textWrapper();
      }

      .right {
        width: 40%;
      }
    }
  }
}
