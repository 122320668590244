@import '../../../../styles/Variables';

.container {
  margin-top: 20px;
  color: $geodocs--file-information-history-task--event-label--color;
}

.icon {
  font-size: inherit;
  vertical-align: middle;
  margin-right: 4px;
}

.childrenContainer {
  margin-left: 20px;
}
