@import '../../../styles/Variables';
.wrapper {
  display: flex;
  width: 100%;
  height: 100%;

  .content {
    margin: auto;
    text-align: center;

    .errorHeading {
      align-items: center;
      display: flex;
      text-align: center;
      color: $geodocs--error--sub-header-color;
      font-size: 36px;
      line-height: 36px;
      height: 74px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: $geodocs--black;
      font-weight: 400;
      letter-spacing: 0.5px;
    }
  }
}

.logo {
  font-weight: 400;
  font-size: 24px;
  margin: 0;
  padding: 24px;
}
