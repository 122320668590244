@import '../../../../styles/Variables';

.actionBar {
  border-bottom-style: solid;
  border-top-style: solid;
}

.chatBubbleButton {
  border-radius: 0 !important;
}

.addButon {
  &:focus {
    color: $geodocs--add-transmittal-response--add-button--color--focus !important;
    background-color: $geodocs--add-transmittal-response--add-button--background-color--focus !important;
  }

  &:hover {
    color: $geodocs--add-transmittal-response--add-button--color--hover !important;
    background-color: $geodocs--add-transmittal-response--add-button--background-color--hover !important;
    border-color: $geodocs--add-transmittal-response--add-button--border-color--hover !important;
  }
}

.expandBtn {
  display: flex;
  color: $geodocs--transmittals-create-transmittal-actions--dropdown-menu--color;
  background-color: $geodocs--transmittals-create-transmittal-actions--dropdown-menu--background-color;
  border: 1px solid $geodocs--transmittals-create-transmittal-actions--dropdown-menu--border-color;
  margin: 0 16px;
  line-height: 1.5;
  height: 40px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.itemsContainer {
  position: absolute;
  top: 17px;
  left: 0;
}

.expandBtnIcon {
  margin-left: 8px;
  font-size: 21px;
  height: 100%;
  display: contents;
}

.btnMenu {
  display: flex;
  flex-direction: row !important;
  height: 40px;
  align-items: center;
  padding-left: 12px;
  padding-right: 6px;
}