@import '../../../../styles/Variables';

.historyItems {
  padding: 0;
  margin: 0;
  list-style: none;

  & > li + li {
    margin-top: 20px;
  }
}
