@import '../../../../styles/Variables';

.searchBoxContainer {
  margin-top: 15px;
  position: relative;
  z-index: 5;
  .searchBox {
    ul:global(.suggestions) {
      max-height: 17rem;
    }
  }
}

.userPill {
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: $geodocs--add-transmittal-message-visibility-selector--user-pill--background-color;
  color: $geodocs--add-transmittal-message-visibility-selector--user-pill--color;
  max-width: 300px;

  & + .searchBoxContainer {
    margin-top: 7px;
  }

  & > span:first-child:first-of-type {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}

.label {
  margin-top: 20px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  display: block;
}

.required {
  position: absolute;
  color: $geodocs--user-selector--required--color;
  margin-left: 8px;
  margin-top: 4px;
}

.disable {
  color: $geodocs--add-transmittal-message-notify-selector--disable--color;
  pointer-events: none;
}
.listSeparator {
  color: $geodocs--add-transmittal-message-notify-selector--disable--color;
  pointer-events: none;
  align-items: center;
  display: flex;
  margin-top: 5px;
  position: relative;

  &:before {
    background-color: rgba(0, 0, 0, 0.25);
    content: '';
    height: 1px;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
.userCount,
.teamDetails {
  float: right;
}

.teamDetails {
  i {
    margin-right: 10px;
  }
}
