@import '../../../styles/Variables';
.itemsContainer {
  display: none;
  position: absolute;
  background-color: $geodocs--drop-down-menu--background-color;
  box-shadow: 0px 0px 4px $geodocs--drop-down-menu--box-shadow--color;
  margin-top: 24px;
  z-index: $geodocs--drop-down-menu--z-index;
}

.items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  &:hover {
    .icon {
      background-color: $geodocs--drop-down-menu--icon--hover--background-color;
    }
    .itemsContainer {
      display: block;
    }
  }

  .disabled {
    background-color: $geodocs--drop-down-menu--icon--disabled--background-color;
  }
}

.menuAbove {
  bottom: 100%;
}
