@import '../../../Variables';

.gridGroupWrapper {
  margin-top: 24px;
  size: 20px;

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: $geodocs--ProjectManagement-transmittalDefaults-header-color;

    &.settingGroup {
      margin-bottom: 16px;
    }
  }
  .actionButtonsWrapper {
    display: flex;
  }
}

.gridTableWrapper {
  .droppableWrapper {
    width: 100%;

    .typeRowWrapper {
      display: flex;
      width: 100%;
      background-color: $geodocs--ProjectManagement-transmittalDefaults-table-row-background-color;
      margin: 10px 0;
      align-items: flex-start;
    }
  }
}
.contentWrapper {
  width: 100% !important;
}
.addNewButton {
  margin-top: 20px;
  margin-bottom: 20px;
  color: $geodocs--ProjectManagement-transmittalDefaults-add-new-type-button-color !important;
}
.transmittalDefaultAccordion {
  div:global(.accordion-panel) {
    display: flex;
    flex-direction: row-reverse;
  }
  div:global(.accordion-panel),
  div:global(.accordion-content) {
    border-top: none !important;
    background-color: transparent !important;
    margin-bottom: 0 !important;
  }
}
@mixin rowWrapper {
  .row {
    padding: 12px;
    font-size: 14px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    display: flex;
    align-items: center;
    line-height: 36px;
    word-break: break-word;
  }
}
@mixin textWrapper {
  .textOverflow {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }
  .inputWrapper {
    width: 100%;
  }
  .charactersLeft {
    font-weight: 400;
    font-size: 12px;
    color: $geodocs--ProjectManagement-transmittalDefaults--disabled--color;
  }
}
.rowWrapperReason {
  display: flex;
  width: 100%;
  margin: 10px 0;
  align-items: flex-start;
  background-color: $geodocs--ProjectManagement-transmittalDefaults-table-row-background-color;
  @include rowWrapper();

  .displayReason {
    padding: 12px;
    font-size: 14px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
    display: flex;
    align-items: center;
    margin-left: 32px;
  }
  @include textWrapper();
  .addNewReasonButton {
    margin-top: 20px;
    margin-bottom: 20px;
    color: $geodocs--ProjectManagement-transmittalDefaults-add-new-type-button-color !important;
    margin-left: 50px;
  }
}
.rowWrapperType {
  display: flex;
  width: 100%;
  margin: 10px 0;
  align-items: flex-start;

  @include rowWrapper();

  @include textWrapper();
}

.iconIndicator {
  color: $geodocs--ProjectManagement-transmittalDefaults--disabled--color;
  padding: 0 12px;
}

.disableRow {
  color: $geodocs--ProjectManagement-transmittalDefaults-breakLine-color;
}
