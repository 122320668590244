@import '../../../styles/Variables';

.container {
  :global {
    .dropdown-wrapper {
      .dropdown-content {
        .dropdown-selected {
          color: #ffffff;
          background-color: #424242;
          .dropdown-down-icon {
            color: #ffffff;
            right: 7px;
          }
        }

        .dropdown-selected:hover {
          color: $geodocs--button--hover--color;
          background-color: $geodocs--button--hover--background-color;
        }

        .dropdown-options {
          background-color: #fafafa;
          box-shadow: 0px 0px 4px rgba(18, 18, 18, 0.25);
          border-color: transparent;

          .dropdown-item:hover {
            color: $geodocs--button--hover--color;
            background-color: $geodocs--button--hover--background-color;
          }
        }
      }
    }
  }
}
