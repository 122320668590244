@import '../../../styles/Variables';

.iconButton {
  border-style: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: $geodocs--button-icon-primary--color;
  background-color: $geodocs--button-icon-primary--background-color;
  display: inline-flex;
  align-items: center;

  &.active {
    &:hover:enabled {
      color: $geodocs--button-icon-primary--hover--color;
      background-color: $geodocs--button-icon-primary--hover--background-color;
    }

    &:focus:enabled {
      color: $geodocs--button-icon-primary--focus--color;
      background-color: $geodocs--button-icon-primary--focus--background-color;
    }
  }

  &:hover:enabled {
    color: $geodocs--black;
  }
}
