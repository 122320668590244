@import '../../../../styles/Variables';

.widgetWrapper {
  margin: 5px 5px 0px 0px;
  width: 32px !important;
  height: 32px !important;
  overflow: hidden !important;
  border-radius: 50% !important;
}

.zoomWidgetWrapper {
  background: $geodocs--visualisation--zoom-widget--background-color;
  margin-top: 5px;
  width: 32px;
  height: 70px;
  overflow: hidden;
}

.sliceWidgetWrapper {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 300px;
  overflow: hidden;
  padding: 8px;

  div {
    overflow: hidden;
  }
}

.measurementWidgetWrapper {
  width: 300px;
}
