@import '../../../styles/Variables';

.headerTitle {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  padding-bottom: 24px;
}

.headerIcon {
  margin-right: 28px;
}

.modalContainer {
  max-height: 500px;
  overflow-y: auto;

  div:global(.heading-right-wrapper) {
    margin-right: 0 !important;
  }

  div:global(.icon-label-wrapper) {
    display: none;
  }

  div:global(.accordion-panel),
  div:global(.accordion-content) {
    margin-bottom: 0 !important;
    border: none !important;
  }
}

.countCircle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: $geodocs--transmittal-visible-modal--count-circle--background-color;
  font-size: 14px;
  color: $geodocs--transmittal-visible-modal--count-circle--color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordianPanelHeader {
  & > div:first-child:first-of-type {
    width: 100%;
  }
}

.accordionPanelTitle {
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  width: 100%;

  .accordionPanelInnerTitleWrapper {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    margin-right: 16px;
  }

  .accordionPanelTitleLabel {
    font-weight: normal;
    word-wrap: break-word;
    word-break: break-all;
  }
}
.accordionPanelTitleLabelTooltip {
  word-wrap: break-word;
}

.peopleIcon {
  margin-right: 16px;
  font-size: 14px;
}

.userItem {
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 15px;

  span.userCircle {
    width: 31px;
  }
  span.displayName {
    margin-left: 8px;
    text-overflow: ellipsis;
  }
}

.modalActions {
  margin-top: 0px;
}
