@import '../../../Variables';

.modal {
  width: $geodocs--modal--width;
  height: auto;

  .charNum {
    font-size: 14px;
    width: 100%;
    text-align: end;
    color: $geodocs--button--disabled--color;
  }

  .fileTypeWrapper {
    position: relative;

    span {
      color: $geodocs--button--disabled--color;
      position: absolute;
      top: 45px;
      right: 25px;

      &.hasError {
        right: 40px;
      }
    }
  }

  :global {
    .field-wrapper > label {
      .input-field {
        input {
          padding-right: 75px;
        }

        .clear-icon {
          right: 60px;
          display: none;
        }
      }
    }
  }
}
