@import '../../../../Variables';

.wrapContainer {
  margin: 0 48px;

  .container {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 0px;
    grid-template-areas:
      'actionBar actionBar'
      'content fileInformation';
    overflow: hidden;
  }

  .content {
    overflow: auto;
    position: relative;
  }
}
