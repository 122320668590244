@import '../../../styles/Variables';

.actionBarSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  button:hover:enabled {
    color: $geodocs--black;
  }
}
