@import '../../../styles/Variables';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $geodocs--overlay--z-index;
  width: 100vw;
  height: 100vh;
  background-color: $geodocs--overlay--background-color;
  opacity: 0.6;
}
