@import '../../../styles/Variables';

.searchBox {
  height: 24px;
  margin-bottom: 2px;

  input {
    max-height: 27px;
  }
}
