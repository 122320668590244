.heading {
  font-weight: 700;
  font-size: 24px;
  margin: 0;
  padding: 24px;
}

.subHeading {
  font-weight: 400;
  font-size: 24px;
  margin: 0;
  padding: 24px;
}
