@import '../../../styles/Variables';

.modalOverlay {
  background-color: $geodocs--transmittals-tab-container-progress--overlay--background-color;
  opacity: 0.9;
}

.loadingSpinner {
  position: fixed;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  z-index: $geodocs--transmittals-tab-container-progress--overlay--spinner-z-index;
}