@import '../../../styles/Variables';

.outlineButton {
  color: $geodocs--button-secondary--color;
  background-color: $geodocs--button-secondary--background-color;
}

.outlineButton:hover {
  color: $geodocs--button--hover--color !important;
}
.outlineButton:not(:enabled) {
  color: $geodocs--button--disabled--color !important;
}
