@import '../../../styles/Variables';

.content {
  padding: 20px 40px;

  table tr td {
    border: 1px solid transparent;
    min-width: 32px;
    padding: 6px;
  }
}
