@import '../../../../styles/Variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 18px;
    font-weight: 400;
  }
}

.native {
  color: $geodocs--native--file--icon--color;
}

.fileNameCell {
  overflow-wrap: anywhere;
  line-height: 30px;
  font-size: 14px;
}
