@import '../../../../styles/Variables';

.uploadForm {
  display: flex;
  flex-direction: column;
  padding: 23px 25px;
  background: $geodocs--upload-form--background-color;
  max-width: 636px;
}
.labelBottom {
  margin: 16px 0 0 0;
  color: $geodocs--go-back-button--text--color;
}
.advanceContainer {
  padding: 18px 0 0 0;
}
