@import '../../../styles/Variables';

.contentFiles {
  padding: 12px 24px 24px 24px;
  margin: 16px 0 0;
  background-color: $geodocs--transmittals-item--item-detail-wrapper--background-color;
  display: flex;
  flex-direction: column;
  width: 100%;

  .contentFilesHeading {
    font-weight: bold;
    margin: 0 0 16px;
    display: flex;
    align-items: center;

    .headingText {
      margin: 0 0 0 16px;
    }
  }
}
.fileCell {
  display: flex;
  align-items: center;
  .fileIcon {
    transform: scaleY(-1);
    padding: 0 8px 0 0;
  }
  .native {
    margin-left: 5px;
    color: $geodocs--native--file--icon--color;
  }
}
