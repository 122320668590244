/*
  Override StyleGuide styles to get the look we want
*/

@import './Variables';
@import './StyleGuideVariables';
@import '../../node_modules/@aurecon-creative-technologies/styleguide/src/scss/component-styles';

input:focus {
  background-color: $geodocs--style-guide--input--focus--background-color;
}

.tabs-wrapper.tabs-medium .tab-list-item > span {
  color: $geodocs--style-guide--tab--color;
}

.tabs-wrapper .tab-content {
  padding: 0;
  height: calc(100% - 41px);
}

.dropdown-wrapper .dropdown-content .dropdown-selected {
  background-color: $geodocs--style-guide--dropdown--background-color;
  color: $geodocs--style-guide--dropdown--color;
}

.dropdown-wrapper .dropdown-content .dropdown-selected:hover,
.dropdown-wrapper .dropdown-content .dropdown-selected.is-open {
  background-color: $geodocs--style-guide--dropdown--hover--background-color;
  border-color: $geodocs--style-guide--dropdown--hover--border-color;
  color: $geodocs--style-guide--dropdown--hover--color;

  .dropdown-down-icon {
    color: $geodocs--style-guide--dropdown--icon--hover--color;
  }
}

.dropdown-wrapper .dropdown-content .dropdown-options {
  height: auto;
  overflow: auto;
  background-color: $geodocs--style-guide--dropdown--list--background-color;
}

.dropdown-wrapper .dropdown-label {
  position: relative;
}

.dropdown-wrapper .dropdown-content .dropdown-options .dropdown-item {
  padding-left: 12px;
  box-sizing: border-box;
  width: 100%;
}

.dropdown-wrapper .dropdown-content .dropdown-options .dropdown-item:hover {
  background-color: $geodocs--style-guide--dropdown--list-item--hover--background-color;
  color: $geodocs--style-guide--dropdown--list-item--hover--color;
}

.pagination-wrapper .pagination-list .pagination-item {
  color: $geodocs--style-guide--pagination-item--color;
  padding: 0;
}

.pagination-wrapper .pagination-list .pagination-chevron .material-icons.chevron-icon {
  color: $geodocs--style-guide--pagination-chevron--color;
}

.search-box-container .field-wrapper .input-field input {
  border-color: $geodocs--style-guide--search--border-color;

  &:focus {
    border-color: $geodocs--style-guide--search--focus--border-color;
  }
}

.search-box-container .field-wrapper .input-field i.clear-icon {
  color: $geodocs--style-guide--search--clear-icon--color;
}

.search-box-container .search-text-wrapper-full-width .suggestions-wrapper .suggestions {
  background-color: $geodocs--style-guide--search--background-color;

  & li:hover {
    background-color: $geodocs--style-guide--search--list-item--hover--background-color;
  }
}

.button.is-icon:hover:enabled:not(.is-loading),
.button.is-icon:focus:enabled:not(.is-loading) {
  background-color: $geodocs--ascent !important;
}

.button.is-text {
  flex: 0;
  margin-left: 0;
  font-weight: 400;
  color: $geodocs--style-guide--button-text--font-color;

  &:hover {
    background-color: $geodocs--style-guide--button-text--hover--background-color;
    > span {
      text-decoration: underline;
    }
  }

  &:focus {
    background-color: $geodocs--style-guide--button-text--focus--background-color;
    border-color: $geodocs--style-guide--button-text--focus--border-color;
    color: $geodocs--style-guide--button-text--font-color;

    > span {
      text-decoration: underline;
    }
  }
}

.tooltip-wrapper span i {
  color: inherit;
}

.date-component {
  .react-daterange-picker {
    height: 27px !important;
  }

  .react-calendar__month-view__days__day {
    color: $geodocs--file-table--date-filter-input--week-day--colour;
  }

  .react-calendar__month-view__days__day--weekend {
    color: $geodocs--file-table--date-filter-input--weekend-day--colour;
  }

  .react-calendar__month-view__days__day--weekend.react-calendar__tile--active {
    color: white;
  }

  .react-calendar__year-view__months__month {
    color: $geodocs--file-table--date-filter-input--month--colour;
  }

  .react-calendar__decade-view__years__year {
    color: $geodocs--file-table--date-filter-input--year--colour;
  }

  .react-calendar__century-view__decades__decade {
    color: $geodocs--file-table--date-filter-input--decade--colour;
  }

  .react-daterange-picker__calendar-button {
    padding-left: 0;
    margin-left: 4px;
  }

  .react-daterange-picker__clear-button {
    padding-right: 0;
  }

  .react-daterange-picker__calendar-button:hover,
  .react-daterange-picker__calendar-button:focus,
  .react-daterange-picker__calendar-button:active {
    background-color: transparent !important;

    &:enabled {
      background-color: transparent !important;
    }

    i {
      color: $geodocs--file-table--date-filter-input--calendar-button--hover--colour !important;
    }
  }

  .react-daterange-picker__clear-button:hover,
  .react-daterange-picker__clear-button:focus,
  .react-daterange-picker__clear-button:active {
    background-color: transparent !important;

    &:enabled {
      background-color: transparent !important;
    }

    i {
      color: $geodocs--file-table--date-filter-input--clear-button--hover--colour !important;
    }
  }
}

.table-main-wrapper .table-wrapper > thead > tr.table-row > td.table-cell.is-sortable-header:hover {
  color: $geodocs--black;
}

.pagination-wrapper .pagination-list .pagination-item:hover {
  color: $geodocs--black;
}

.pagination-wrapper .pagination-list .pagination-chevron .material-icons.chevron-icon:hover {
  color: $geodocs--black;
}

.checkbox-label .checkbox {
  border-color: $geodocs--checkbox--border-color;
}

.checkbox-label .checkbox.is-checked,
.checkbox-label .checkbox.is-checked:hover,
.checkbox-label .checkbox.is-indeterminate:hover {
  color: $geodocs--checkbox-is-checked--color;
  background-color: $geodocs--checkbox-is-checked--background-color;
}

.combobox-wrapper .combobox-content .combobox-selected {
  background-color: $geodocs--style-guide--dropdown--background-color;
  color: $geodocs--style-guide--dropdown--color;
}

.combobox-wrapper .combobox-content .options-wrapper .options li.is-selected,
.combobox-wrapper .combobox-content .options-wrapper .options li:hover {
  background-color: rgba($geodocs--ascent, $light--opacity);
}

.combobox-wrapper .combobox-content .options-wrapper .options li:hover {
  background-color: rgba($geodocs--ascent, $light--opacity);
}
