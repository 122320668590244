@import '../../../styles/Variables';

.cardWrapper {
  background: $geodocs--task-information-file--cardWrapper--color;
  margin: 24px 0;
  padding: 24px;
  gap: 24px;

  .cardHeader {
    display: flex;
    align-items: center;
    height: 21px;
    gap: 16px;
    margin-bottom: 21px;

    .cardHeaderIcon {
      line-height: 1;
      font-size: 21px;
    }

    span {
      font-weight: 700;
      color: $geodocs--task-information-file--title--color;
    }
  }

  .cardFooter {
    display: flex;
    margin: 24px 0 0 0;
    justify-content: flex-end;
  }

  .fileIcon {
    transform: scaleY(-1);
    padding: 0 8px 0 0;
  }

  .native {
    margin-left: 5px;
    color: $geodocs--native--file--icon--color;
  }
}
