@import '../../../Variables';

.pageWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .heading {
    color: $geodocs--team-management-heading--color;
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    width: 100%;
    display: flex;
    flex-grow: 1;

    .title {
      width: 80%;
    }
    .buttons {
      width: 20%;
      display: flex;
      justify-content: flex-end;

      .editIcon {
        cursor: pointer;
        position: relative;
        top: 3px;
      }
    }
  }

  .addAcceptor {
    background-color: $geodocs--adding-acceptor-for-delivery-team--background-color;
    padding: 20px;

    .addAcceptorLabel {
      color: $geodocs--adding-acceptor-for-delivery-team--color;
      font-weight: 700;
      line-height: 21px;
      font-size: 14px;
    }
    .addUserRow {
      margin-top: 16px;
    }
  }

  .grantingAccessBox {
    padding: 24px 0 48px;

    .headingWrapper {
      display: flex;
      padding: 0 0 24px;
    }

    .searchBoxLabel {
      padding: 0 0 8px;
    }
  }

  .save {
    display: flex;
    justify-content: flex-end;
  }

  .tooltipIcon {
    padding-left: 8px;
  }

  .tooltipText {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
