.mapViewContainer {
  display: flex;
  position: relative;
  flex: 1 1 100%;
  width: 100%;
  flex-wrap: wrap;
}

.mapView {
  width: 100%;
  height: 100%;
}

.rightTopWidgets {
  position: absolute;
  right: 10px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-end;
  min-height: 30px;
}

.leftTopWidgets {
  margin-top: 24px;
}
