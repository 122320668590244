@import '../../../../styles/Variables';

.container {
  display: flex;

  .medataColumn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 24px;
    width: 100%;
    .uploadHeader {
      order: -1;
      flex: 1 0 100%;
    }
  }
}

.metadataWrapper {
  width: auto;
  flex-direction: row;
}

.fileTableWrapper {
  width: auto;
  flex: 1;
  max-width: 100%;
  padding: 90px 0 0;
  overflow: hidden;
}

.rightContainer {
  display: flex;
  flex-direction: column;
}

.filesContainer {
  padding: 24px;
  background-color: $geodocs--upload-form--background-color;

  .fileHeading {
    display: flex;
    align-items: center;
    padding-bottom: 24px;

    .numberFiles {
      font-size: 14px;
      font-weight: bold;
    }

    .actionButtons {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 16px;
      }
    }
  }

  .filesEnd {
    padding: 24px 0 0;

    .totalFiles {
      font-size: 14px;
      color: $geodocs--button--disabled--color;
    }
  }

  .filesTable {
    max-height: 660px;
    min-height: 310px;
    overflow: auto;

    & thead {
      position: sticky;
      top: 0;
      z-index: 10;
    }

    .fileSizeHeader {
      margin: 0 70px;
    }

    td.supersedeCell {
      background-color: $geodocs--upload-form--dropdown--disabled-background-color !important;
      color: $geodocs--upload-form--supersede--metadata-color;
    }

    td:first-child {
      border-left: 0;
    }
  }
}

.exportButton {
  padding: 12px;
  border: 1px solid $geodocs--black;
  color: $geodocs--black;
  text-decoration: none;

  &:hover {
    background: $geodocs--upload-form--export-btn--hover-background-color;
    border: 1px solid $geodocs--upload-form--export-btn--hover-background-color;
  }
}

.message {
  display: flex;
  align-items: center;
  margin: 0 0 24px;

  i {
    margin-right: 24px;
    &.success {
      color: $geodocs--upload-file-ready-status--color;
    }

    &.warning {
      color: $geodocs--icon--warning;
    }
  }

  p {
    margin: 0;
  }
}
