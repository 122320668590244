@import '../../Variables';

.settingPage {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 84px);

  .header {
    max-height: 84px;

    .goBackBtn {
      margin: 0;
    }
  }

  div:global(.tab-content) {
    min-height: calc(100% - 42px);
    overflow: auto;
  }
}
