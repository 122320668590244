@import '../../../styles/Variables';

.appDrawer {
  display: flex;
  justify-content: flex-start;
  max-width: 90%;
  max-height: 90%;
  height: calc(100% - 145px);
  background-color: $geodocs--style-guide--drawer--background-color;
  text-align: center;
  font-size: 14px;
  padding: 20px 24px 23px 20px;
  z-index: 1000;
  position: absolute;
  top: 60px;
  left: 200px;
  transition: transform 0.4s ease-in-out;
  transform: translateX(-250%);
  box-shadow: 0.125rem 0.125rem 0.25rem $geodocs--sidebar--box-shadow-color;
}
.sidebarClose {
  left: 2.5%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  z-index: 1000;
}

.overlay[data-open='true'] {
  display: block;
}

.appDrawer[data-open='true'] {
  transform: translateX(0);
}

.appContainer {
  height: 90%;
  width: 100px;
  outline: none;
  overflow-y: hidden;
  margin-bottom: 15px;
  -ms-overflow-style: scroll; // IE 10+
  scrollbar-width: none; // Firefox
}
.appContainer::-webkit-scrollbar {
  display: none; // Safari and Chrome
}

.arrowUp {
  position: sticky;
  top: 50px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  background: $geodocs--style-guide--drawer--background-color;
  border: none;
  color: $geodocs--drawer--scroll-arrow--color;
}
.arrowDown {
  position: relative;
  bottom: 30px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  background: $geodocs--style-guide--drawer--background-color;
  border: none;
  color: $geodocs--drawer--scroll-arrow--color;
}
.arrowIcon {
  font-size: 50px;
}

.appLink {
  cursor: pointer;
  padding-bottom: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.linkLabel {
  cursor: pointer;
  color: $geodocs--drawer-link-label--color;
  text-decoration: none;
  padding-top: 30px;
  font-size: 14px;
}
.appIcon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  margin-bottom: 10px;
}
