@import '../../../styles/Variables';

.tableCell {
  padding: 0 !important;
}

.cell {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.icon {
  width: 38px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemsContainer {
  margin-top: 40px;
  margin-right: 4px;
}
