@import '../../../styles/Variables';

.initiatorTooltipItem {
  display: flex;
  align-items: center;

  span {
    margin-right: 8px;
  }
}
.userName,
.userEmail {
  font-size: 14px;
  word-break: break-all;
  min-height: 20px;
  line-height: 20px;
  margin-bottom: 8px;
  color: $geodocs--transmittals-initiator--user-name--color;
}

.userName {
  font-weight: bold;
}

.userEmail {
  line-height: 21px;
  margin-bottom: 0px;
  font-weight: normal;
}

.teamIcon {
  color: $geodocs--transmittals-initiator--team-icon--color !important;
}
