@import '../../../../Variables';

.pageWrapper {
  p {
    margin: 24px 0;
  }

  .settingsContainer {
    padding: 0 0 24px;

    .headingWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .headingLabel {
      font-size: 20px;
      font-weight: 400;
      color: $geodocs--system-users-list--page-heading-label-color;

      &.header {
        margin-top: 0;
      }
    }

    .subHeadingLabel {
      font-size: 16px;
      font-weight: 400;
      color: $geodocs--system-users-list--page-heading-label-color;
    }

    .settingLabel {
      font-size: 16px;
      font-weight: 400;

      &.first {
        margin-bottom: 0;
      }
    }

    .splashDescription {
      font-weight: 600;
    }

    .checkboxSetting {
      margin-top: 17px;
    }
  }

  .divider {
    border: 1px solid $geodocs--divider--color;
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
    padding: 8px 0;
  }

  .btnSave {
    margin: 24px 0;
  }
}
