@import '../../../styles/Variables';

%workflowPill {
  color: $geodocs--workflow-pill--text-color !important;
}

.taskTeamReview {
  @extend %workflowPill;
  background-color: $geodocs--workflow-pill--task-team-review--background-color !important;
}

.shareApproval {
  @extend %workflowPill;
  background-color: $geodocs--workflow-pill--share-approval--background-color !important;
}

.deliveryTeamReview {
  @extend %workflowPill;
  background-color: $geodocs--workflow-pill--delivery-team-review--background-color !important;
}

.publishAuthorise {
  @extend %workflowPill;
  color: $geodocs--black !important;
  background-color: $geodocs--workflow-pill--publish-authorise--background-color !important;
}

.publishAccept {
  @extend %workflowPill;
  background-color: $geodocs--workflow-pill--publish-accept--background-color !important;
}
