@import '../../../Variables';

.distributionListContainer {
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  height: 100%;
  overflow: auto;

  .heading {
    color: $geodocs--team-management-heading--color;
    padding: 0 0 24px;
    font-size: 24px;
    width: 100%;
    display: flex;
    flex-grow: 1;

    .title {
      width: 90%;
      display: flex;
      word-wrap: break-word;
      word-break: break-all;
    }

    .buttonsIcon {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 18px;
      color: $geodocs--button-icon-primary--color;

      .editIcon {
        cursor: pointer;
        position: relative;
        top: 3px;
      }
    }
  }
  .header {
    padding: 0 0 24px;

    .teamInfo {
      padding: 10px 0;

      .row {
        width: 100%;

        &.label {
          font-size: 14px;
          line-height: 21px;
          color: $geodocs--teamManagement-noticed-text-color;
          font-weight: bold;
          padding: 0 0 16px;
        }
        .actionButton {
          margin-left: 16px;
        }
      }
    }
  }
}

.editListModal {
  .cellInputLeft {
    padding: 0 8px 0 0;
  }
  .modalActions {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -50%;
      height: 1px;
      width: 155%;
      background-color: rgba(0, 0, 0, 0.25);
      overflow-x: hidden;
    }
    margin-top: 24px;
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    align-items: center;

    button {
      margin-left: 12px;
    }
  }
}
