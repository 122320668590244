@import '../../../../styles/Variables';

.previewBox {
  border: 1px solid $geodocs--file-information-details--preview-image--border-color;
  width: 320px;
  height: 200px;
  display: table-cell;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  position: relative;

  .thumbnailBox {
    width: 320px;
    height: 200px;
  }

  .textPreviewBox {
    line-height: 0em;
    color: $geodocs--file-information-details--value-light--color;
  }
  .previewButtonBox {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.fieldListHeading {
  margin: 0;
  padding: 24px 0 8px 0;
  font-size: 18px;
}

.fieldList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.field {
  display: flex;
  flex-direction: row;
  padding: 11px 0;
  box-shadow: inset 0px -1px 0px $geodocs--file-information-details--field--box-shadow-color;
  font-size: 14px;
}

.fieldLabel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 142px;
  font-weight: 700;
  padding-right: 0.5rem;
}

.fieldValue {
  width: 186px;
}

.fieldValueLight {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.5px;
  color: $geodocs--file-information-details--value-light--color;
}

.originalFilename {
  overflow-wrap: anywhere;
  line-height: 20px;
}
.fieldVisible {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-wrap: break-word;
}

.temporaryAccessUser {
  font-size: 14px;
  line-height: 21px;
}
