.modal {
  width: 500px;
  height: auto;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 90px;
}

.icon {
  font-weight: 600;
  font-size: 50px;
}
