@import '../../../styles/Variables';

.wrapContainer {
  display: flex;
  justify-content: space-between;
  width: inherit;
  margin-left: 24px;
}

.icon {
  font-size: 16px;
}

.tooltipIconSchedule {
  color: $geodocs--locked-content-color;
  margin-left: 5px;
}

.taskTeamTitle {
  font-weight: 700;
}

@media screen and (max-width: 992px) {
  .container {
    font-size: 12px;
  }
}
