@import '../../../Variables';

.fromWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 24px;

    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: $geodocs--ProjectManagement-add-project-title-color;
  }
  .actions {
    display: flex;
    gap: 16px;

    .actionIcon {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 18px;
      color: $geodocs--button-icon-primary--color;
    }

    .editIcon {
      &:hover {
        cursor: pointer;
        color: $geodocs--ascent;
      }
    }
  }
  .formDisplayField {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 24px;

    .value {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .formControlLabel {
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 14px;

    .required {
      position: inherit;
      color: $geodocs--user-selector--required--color;
      margin-left: 8px;
      margin-top: 4px;
    }
  }
}
