@import '../../../Variables';

.pageWrapper {
  display: flex;
  flex-direction: row;
  height: auto;
  min-height: 100%;
}

.teamManagementTree {
  width: 400px;
}

.left {
  width: 400px;
  border-right: 1px solid $geodocs--teamManagement-border-left-color;
  min-height: 100%;
  height: auto;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 40px;
  overflow: auto;
  min-height: 100%;
  height: auto;

  .noticed {
    border: 1px solid $geodocs--teamManagement-noticed-border-color;
    padding: 16px;
    height: max-content;
    color: $geodocs--teamManagement-noticed-text-color;
    border-left: solid 4px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 700;
      color: $geodocs--style-guide--tab--color;
      line-height: 21px;

      .closedBtn {
        font-size: 20px;
        width: 20px;
        line-height: 21px;

        &:hover {
          background-color: $geodocs--teamManagement-noticed-close-icon-hover-color;
        }
      }
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  .comingSoon {
    border: 1px solid $geodocs--teamManagement-noticed-border-color;
    padding: 16px;
    height: max-content;
    color: $geodocs--teamManagement-noticed-text-color;
    border-left: solid 4px;
  }
}
