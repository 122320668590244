@import '../../../styles/Variables';

.pageContent {
  flex: 1 1;
  display: flex;
  align-items: center;
  align-content: flex-end;
  height: 80%;
  padding: 60px;
  flex-direction: row;

  .content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h1 {
      font-weight: 700;
      font-size: 2.5rem;
    }

    h2 {
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 1em;
    }
    .buttons {
      display: flex;
      margin-top: 36px;
      button {
        margin-right: 1.25rem;
      }
    }

    .textOnly {
      border: none;
      outline: none;
      background-color: transparent;
      text-decoration: underline;
      color: $geodocs--button-secondary--color !important;
      &:hover {
        color: $geodocs--login--support-hub--hover-color !important;
      }
    }

    @media screen and (min-width: 576px) {
      width: 25rem;
    }
  }
}

.footer {
  grid-area: footer;
}
