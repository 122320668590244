@import '../../../../styles/Variables';

.searchContentWrapper {
  display: block;
  width: 100%;
  flex-wrap: wrap;
  float: right;
  .label {
    padding: 0px 16px;
    font-weight: 700;
  }
  .searchBox {
    width: 400px;
    padding: 12px 0;
  }
  .searchContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 600px;
    float: right;    
  }
}
