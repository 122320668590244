@import '../../../../Variables';

.pageWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.systemSettingsTree {
  width: 400px;
}

.left {
  width: 400px;
  border-right: 1px solid $geodocs--teamManagement-border-left-color;
}

.right {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px 40px;
}
