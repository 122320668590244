@import '../../../styles/Variables';

.centreOnPage {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $geodocs--center-on-page--z-index;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
